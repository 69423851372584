<template>
  <div class="mod">
    <div class="container">
      <head-nav :now-page="nowPage"></head-nav>
    </div>
    <div class="body">
      <div class="wrapper1">
        <div style="display: flex;align-items: start;flex-direction: column;margin-right: 160px;">
          <span class="title66">环境权益资产数智化解决方案提供商</span>
          <span class="meta66">您身边的“环境权益管家”</span>
        </div>
        <div>
          <img
            class="item"
            src="./images/img_2.png"
          />
        </div>
      </div>
    </div>
    <div class="container">
      <div class="box2">
        <div style="display: flex;position: relative;align-items: center;margin-top: 80px;margin-bottom: 20px;">
          <div class="titleBox2">
            <img src="./images/img_3.png" class="actionBg1">
            <div class="group2">
              <span class="textTitle">企业</span>
              <span class="textTitle1">解决方案</span>
            </div>
            <img src="./images/img_4.png" class="buttonBg">
          </div>
        </div>
        <div class="textBox3">
          <p style="line-height: 24px">专注于解决企业碳数据收集难、管理难等问题，助力企业建设碳资产管理体系，提升企业整体碳资产管理能力和经营效益，为企业塑造绿色形象并稳步实现碳中和。</p>
        </div>
      </div>
      <div class="box3">
        <img src="./images/img_0.png" class="imgBox3">
        <ul class="ulBox3">
          <li>
            <div class="title">
              <div class="icon2 icon"></div>
              <div class="text">以数据驱动决策</div>
            </div>
            <div class="info">
              <p class="infoText">建立完善的数据管理体系，保障数据的准确性和可靠性，以识别关键趋势和潜在机会，驱动战略决策制定落地。</p>
            </div>
          </li>
          <li>
            <div class="title">
              <div class="icon1 icon"></div>
              <div class="text">全方位专业支持</div>
            </div>
            <div class="info">
              <p class="infoText">涵盖碳管理多个关键环节，可一站式获取碳管理工具，解决面临复杂的碳市场环境挑战，提升企业碳管理能力。</p>
            </div>
          </li>
          <li>
            <div class="title">
              <div class="icon3 icon"></div>
              <div class="text">统一管理与监控</div>
            </div>
            <div class="info">
              <p class="infoText">整合多数据源、业务线数据，通过多维度数据分析和可视化展示，提供全面及时的数据洞察和实时全局的监管。</p>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="box4">
      <img src="./images/Group 4940 1.png" class="imgBox4">
      <div class="wordDivBox4">
        <span class="wordBox4">企业解决方案</span>
        <span class="labelBox4">我们致力于为企业搭建双碳管理体系，提供碳核算、碳交易、脱碳规划的全方位支持，助力提高企业的整体碳管理能力，为企业打造绿色形象，实现碳中和，并为在低碳经济时代保持领先地位提供有力保障。通过我们的综合性解决方案，您将获得准确的碳数据分析和战略指导，助力实现气候目标并创造可持续的商业成果。让我们一起迈向低碳未来，共同打造一个可持续发展的企业。</span>
      </div>
    </div>
    <div class="container">
      <bottom-nav></bottom-nav>
    </div>
  </div>
</template>

<script>
import HeadNav from '@/components/Head/headNav' // @ is an alias to /src
import BottomNav from '@/components/Bottom/bottomNav'
export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    HeadNav,
    // eslint-disable-next-line vue/no-unused-components
    BottomNav
  },
  name: 'index',
  data () {
    return {
      nowPage: 'solution'
    }
  }
}
</script>

<style scoped="scoped" src="./index.css">

</style>
