<template>
    <div class="header">
      <nav class="main-nav d-flex justify-content-center" style="display: flex;width: 100%;height: 40px;margin: 15px 0;">
        <img
          class="actionBg"
          src="./images/img_0.png"
          v-on:click="toHome"
        />
        <ul class="nav">
          <li>
            <a class="active" href="/">
              <span :class="nowPage=='home'||nowPage==''?'menu-text-active':'menu-text'"> 首页</span>
            </a>
            <div :class="nowPage=='home'||nowPage==''?'lineActive':'liceNone'"></div>
          </li>
          <li>
            <a>
              <span :class="nowPage=='product'?'menu-text-active':'menu-text'">产品中心</span>
              <i class="fa fa-angle-down"></i>
            </a>
            <div :class="nowPage=='product'?'lineActive1':'liceNone1'"></div>
            <ul class="dropdown-submenu dropdown-hover">
              <li><a v-on:click="toProduct('Two')">气候管理</a></li>
              <li><a v-on:click="toProduct('One')">碳资产管理</a></li>
              <li><a v-on:click="toProduct('Three')">绿能跟踪</a></li>
              <li><a v-on:click="toProduct('Four')">碳迹源</a></li>
            </ul>
          </li>
          <li>
            <a>
              <span :class="nowPage=='solution'?'menu-text-active':'menu-text'"> 解决方案</span>
              <i class="fa fa-angle-down"></i>
            </a>
            <div :class="nowPage=='solution'?'lineActive1':'liceNone1'"></div>
            <ul class="dropdown-submenu dropdown-hover">
              <li><a v-on:click="toSolution('One')">企业解决方案</a></li>
              <li><a v-on:click="toSolution('Two')">区域解决方案</a></li>
              <li><a v-on:click="toSolution('Three')">碳资产解决方案</a></li>
            </ul>
          </li>
          <li>
            <a v-on:click="toWe()">
              <span :class="nowPage=='aboutWe'?'menu-text-active':'menu-text'">关于我们</span>
            </a>
            <div :class="nowPage=='aboutWe'?'lineActive1':'liceNone1'"></div>
          </li>
        </ul>
        <div style="width: 50%;margin-right: 10px">
          <a-input-search placeholder="请输入关键词进行搜索"  class="input" @search="onSearch" />
          <a style="float: right;line-height: 40px" href="http://greenaccounter.cn/enindex.html">
            <span style="color: #0676F9">中</span><span>/</span><span style="color:#999999;">En</span>
          </a>

        </div>
      </nav>
    </div>
</template>

<script>

export default {
  name: 'headNav',
  props: {
    nowPage: String
  },
  data () {
    return {
    }
  },
  methods: {
    toProduct (e) {
      this.nowPage = 'product'
      this.$router.push('/product' + e)
      // 将页面滚动到顶部
      window.scrollTo(0, 0)
      console.log('this.nowPage', this.nowPage)
    },
    toSolution (e) {
      this.nowPage = 'solution'
      this.$router.push('/solution' + e)
      // 将页面滚动到顶部
      window.scrollTo(0, 0)
      console.log('this.nowPage', this.nowPage)
    },
    toWe () {
      this.nowPage = 'aboutWe'
      this.$router.push('/aboutWe')
      // 将页面滚动到顶部
      window.scrollTo(0, 0)
      console.log('this.nowPage', this.nowPage)
    },
    toHome () {
      this.nowPage = 'home'
      this.$router.push('/')
      console.log('this.nowPage', this.nowPage, 'home')
    },
    toEnindex () {
      this.nowPage = 'Enindex'
      this.$router.push('enindex.html')
      // 将页面滚动到顶部
      window.scrollTo(0, 0)
      console.log('this.nowPage', this.nowPage)
    },
    onSearch (value) {
      console.log(value)
    }
  }
}
</script>

<style scoped="scoped">
.header {
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
  background-color: #ffffff;
  width: 100%;
  height: 70px;
}
.actionBg {
  /*margin-top: 30px;*/
  /*margin-right: 56px;*/
  width: 16%;
  height: 37px;
  white-space: normal;
  cursor: pointer;
}
.nav{
  width: 100%;
  padding: 0;
  margin-top: 4px;
}
.main-nav > ul > li {
  display: inline-block;
  position: relative;
  /*padding: 30px 0;*/
}
.main-nav > ul > li:hover .dropdown-hover {
  visibility: visible;
  opacity: 1;
  top: 100%;
}

.main-nav > ul > li:hover .dropdown-hover-2 {
  visibility: visible;
  opacity: 1;
  top: 100%;
}

.main-nav > ul > li > a {
  display: block;
  padding: 8px 28px;
  font-size: 15px;
  text-transform: capitalize;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  line-height: 16px;
}

/*.main-nav > ul > li > a.active {*/
/*  color: #0676F9;*/
/*}*/

.main-nav > ul > li > a i {
  margin-left: 0px;
  font-size: 15px;
  vertical-align: middle;
}

.main-nav > ul > li > a .menu-text {
  position: relative;
  font-size: 16px;
  margin-right: 50px;
  font-weight: 400;
}
.mega-menu .menu-colum ul li .mega-menu-text {
  position: relative;
  font-size: 18px;
  font-family: "Oswald", sans-serif;
  display: table-caption;
  padding: 0px 20px 0px 0px;
  margin: 0px 0px 10px 20px;
}

.mega-menu .menu-colum ul li .mega-menu-text:after {
  position: absolute;
  left: 0;
  bottom: 0;
  margin: auto;
  width: 100%;
  border-bottom: 2px solid #000000;
  content: "";
  z-index: 1;
}
/*--- Dropdwon ---*/
.dropdown-submenu {
  background: #ffffff;
  padding: 20px 0 24px;
  width: 230px;
  -webkit-box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
}

.dropdown-submenu > li > a {
  padding: 10px 20px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  text-transform: capitalize;
  font-family: "Poppins", sans-serif;
  line-height: 18px;
  position: relative;
  color: #454545;
  font-size: 14px;
  font-weight: 400;
}

.dropdown-submenu > li > a:hover {
  color: #0676F9;
}

.dropdown-submenu > li > a.active {
  color: #0676F9;
}

.dropdown-submenu > li > a > i {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 2%;
  font-size: 15px;
  padding-right: 15px;
}
.dropdown-hover {
  position: absolute;
  top: 110%;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all .3s linear 0.2s;
  -o-transition: all .3s linear 0.2s;
  transition: all .3s linear 0.2s;
  z-index: 999;
  list-style: none;
}
a{
  text-decoration: none;
  color: black;
}
a:hover{
  color: #0676F9;
}
.input{
  width: 200px;
  height: 30px;
  float: right;
  margin: 5px 10px;
}
.menu-text-active{
  color: #0676F9;
  position: relative;
  font-size: 16px;
  margin-right: 50px;
  font-weight: 500;
}
.liceNone{
  width: 40px;
  height: 8px;
}
.lineActive{
  width: 40px;
  height: 8px;
  background: linear-gradient(89deg, #32E5FF 3%, #00C2FF 24%, #03A4FF 77%, #0085FF 100%);
  border-radius: 10px 10px 10px 10px;
  opacity: 1;
  margin-left: 25px;
  margin-top: 10px;
}
.liceNone1{
  width: 40px;
  height: 8px;
}
.lineActive1{
  width: 40px;
  height: 8px;
  background: linear-gradient(89deg, #32E5FF 3%, #00C2FF 24%, #03A4FF 77%, #0085FF 100%);
  border-radius: 10px 10px 10px 10px;
  opacity: 1;
  margin-left: 40px;
  margin-top: 10px;
}

</style>
