<template>
  <div>
    <a-carousel  ref="img" @afterChange="handlePageChange">
      <div v-for="(item, index) in slotArr" :key="index" style="padding: 24px" class="rollBox">
        <slot :name="item[(index+1)]"></slot>
      </div>
    </a-carousel>
    <div class="changeDiv">
      <div v-on:click="handlePrev" class="leftBtn changeBtn"></div>
      <div v-on:click="handleNext" class="rightBtn changeBtn"></div>
      <div class="changeLine">
        <div class="changeLine">
          <div :class="currentIndex==0?'lineActive':'line'"></div>
          <div :class="currentIndex==1?'lineActive':'line'"></div>
          <div :class="currentIndex==2?'lineActive':'line'"></div>
        </div>
        <div class="changeLine">
          <div :class="currentIndex==3?'lineActive':'line'"></div>
          <div :class="currentIndex==4?'lineActive':'line'"></div>
          <div :class="currentIndex==5?'lineActive':'line'"></div>
        </div>
        <div class="changeLine">
          <div :class="currentIndex==6?'lineActive':'line'"></div>
          <div :class="currentIndex==7?'lineActive':'line'"></div>
          <div :class="currentIndex==8?'lineActive':'line'"></div>
          <div :class="currentIndex==9?'lineActive':'line'"></div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'rollType5',
  props: {
    slotArr: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      currentIndex:0,
      indexStatus:false,
    }
  },
  mounted () {
    // this.$nextTick(() => {
    //   this.$refs.img.onChange = this.handlePageChange;
    // });
    this.timer = setInterval(() => {
      this.indexStatus = true
    },900)
  },
  methods: {
    handlePrev() {
      // 通过上边指定的ref 来操作
      if(this.indexStatus){
        this.$refs.img.prev();
        if(this.currentIndex>0){
          this.currentIndex -= 1
        }else{
          this.currentIndex = 9
        }
        this.indexStatus = false
      }

    },
    handleNext() {
      if(this.indexStatus){
        this.indexStatus = false
        this.$refs.img.next();
        if(this.currentIndex<9){
          this.currentIndex += 1
        }else{
          this.currentIndex = 0
        }
      }
    },
    handlePageChange(index){
      console.log('当前index',index)
    }
  }
}
</script>

<style scoped>
/* For demo */
.ant-carousel >>> .slick-slide {
  text-align: center;
  line-height: 140px;
  overflow: hidden;
}

.ant-carousel >>> .slick-slide h3 {
  color: #fff;
}
.changeDiv{
  display: flex;
  width: 100%;
  height: 40px;
}
.changeBtn{
  width: 7px;
  height: 13px;
  margin-right: 30px;
}
.leftBtn{
  background: url("../../assets/img1/lunboIcon.png") no-repeat;
  background-size: 100%;
}
.rightBtn{
  background: url("../../assets/img1/lunboIcon.png") no-repeat;
  transform: rotate(180deg);
  background-size: 100%;
}
.leftBtn:hover{
  background: url("../../assets/img1/lunboIconActive.png") no-repeat;
  transform: rotate(-180deg);
  background-size: 100%;
}
.rightBtn:hover{
  background: url("../../assets/img1/lunboIconActive.png") no-repeat;
  transform: rotate(0);
  background-size: 100%;
}
.changeLine{
  display: flex;
  margin-top: 5px;
}
.line,.lineActive{
  width: 40px;
  height: 2px;
  margin-left: 20px;
}
.line{
  background: #D9D9D9;
}
.lineActive{
  background: linear-gradient(89deg, #32E5FF 3%, #00C2FF 24%, #03A4FF 77%, #0085FF 100%);
}
</style>
