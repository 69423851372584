import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Home from '../views/Home.vue'
// 碳资产管理
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import productOne from '../views/Product/ProductOne/index'
// 气候管理
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import productTwo from '../views/Product/ProductTwo/index'
// 绿能跟踪
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import productThree from '../views/Product/ProductThree/index'
// 碳迹源小程序
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import productFour from '../views/Product/ProductFour/index'
// 解决方案1.集团化解决方案
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import solutionOne from '../views/Solution/SolutionOne/index'
// 解决方案2.区域化解决方案
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import solutionTwo from '../views/Solution/SolutionTwo/index'
// 解决方案3.碳资产解决方案
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import solutionThree from '../views/Solution/SolutionThree/index'
// 主页
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import myHome from '../views/home/index'
// 关于我们
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import aboutWe from '../views/AboutWe/index'
// 测试
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import test from '../views/test'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/home',
    name: 'Home',
    component: Home
  },
  {
    path: '/productOne',
    name: 'productOne',
    component: productOne
  },
  {
    path: '/productTwo',
    name: 'productTwo',
    component: productTwo
  },
  {
    path: '/productThree',
    name: 'productThree',
    component: productThree
  },
  {
    path: '/productFour',
    name: 'productFour',
    component: productFour
  },
  {
    path: '/solutionOne',
    name: 'solutionOne',
    component: solutionOne
  },
  {
    path: '/solutionTwo',
    name: 'solutionTwo',
    component: solutionTwo
  },
  {
    path: '/solutionThree',
    name: 'solutionThree',
    component: solutionThree
  },
  {
    path: '/aboutWe',
    name: 'aboutWe',
    component: aboutWe
  },
  {
    path: '/test',
    name: 'test',
    component: test
  },
  {
    path: '/',
    name: 'home2',
    component: myHome
  }
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
