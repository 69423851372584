var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mod"},[_c('div',{staticClass:"container"},[_c('head-nav',{attrs:{"now-page":_vm.nowPage}})],1),_vm._m(0),_c('div',{staticClass:"container"},[_vm._m(1),_c('div',{staticClass:"row"},[_c('div',{staticClass:"group3",on:{"click":function($event){return _vm.toProduct('Two')}}},[_c('img',{staticClass:"picture",attrs:{"src":require("./images/img_5.png")}}),_c('span',{staticClass:"label2"},[_vm._v("气候管理")])]),_c('div',{staticClass:"group4",on:{"click":function($event){return _vm.toProduct('One')}}},[_c('img',{staticClass:"image",attrs:{"src":require("./images/img_6.png")}}),_c('span',{staticClass:"word2"},[_vm._v("碳资产管理")])]),_vm._m(2),_c('div',{staticClass:"group6",on:{"click":function($event){return _vm.toProduct('Four')}}},[_c('img',{staticClass:"bitmap",attrs:{"src":require("./images/img_8.png")}}),_c('span',{staticClass:"subtitle"},[_vm._v("碳迹源")])])])]),_vm._m(3),_vm._m(4),_vm._m(5),_c('div',{staticClass:"container"},[_c('BottomNav')],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"body"},[_c('div',{staticClass:"wrapper1"},[_c('div',{staticStyle:{"display":"flex","align-items":"start","flex-direction":"column","margin-right":"160px"}},[_c('span',{staticClass:"title66"},[_vm._v("环境权益资产数智化解决方案提供商")]),_c('span',{staticClass:"meta66"},[_vm._v("您身边的“环境权益管家”")])]),_c('div',[_c('img',{staticClass:"item",attrs:{"src":require("./images/img_2.png")}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"submain"},[_c('img',{staticClass:"actionBg1",attrs:{"src":require("./images/img_3.png")}}),_c('div',{staticClass:"group2"},[_c('span',{staticClass:"title"},[_vm._v("产品")]),_vm._v(" "),_c('span',{staticClass:"title1"},[_vm._v("中心")])]),_c('img',{staticClass:"buttonBg",attrs:{"src":require("./images/img_4.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"group5"},[_c('img',{staticClass:"figure",attrs:{"src":require("./images/img_7.png")}}),_c('span',{staticClass:"label1"},[_vm._v("绿能跟踪")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row2Item0"},[_c('img',{staticClass:"cover",attrs:{"src":require("./images/img_10.png")}}),_c('div',{staticClass:"group8"},[_c('span',{staticClass:"label3"},[_vm._v("电碳协同管理")]),_c('span',{staticClass:"article1"},[_vm._v("·通过多账户机制设置，对可再生电力发电侧和用电测提供能量和碳协同管理； ")]),_c('span',{staticClass:"article2"},[_vm._v("·可实现与范围2、范围3间接排放实时关联，助力制定有效的基于市场的可持续发展计划。")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"row3"},[_c('div',{staticClass:"group9"},[_c('span',{staticClass:"tag2"},[_vm._v("转移跟踪管理")]),_c('span',{staticClass:"article1"},[_vm._v("·可灵活选择捆绑或非捆绑类型、采用实时或定期转移方式、进行强制或自愿使用行为等，实现绿能转移和使用的跟踪管理； ")]),_c('span',{staticClass:"article2"},[_vm._v("·满足不同用户类型的绿色能源属性确权管理。")])]),_c('img',{staticClass:"banner1",attrs:{"src":require("./images/img_11.png")}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row2Item1"},[_c('img',{staticClass:"cover1",attrs:{"src":require("./images/img_12.png")}}),_c('div',{staticClass:"group10"},[_c('span',{staticClass:"tag3"},[_vm._v("交叉验证管理")]),_c('span',{staticClass:"article1"},[_vm._v("·基于先进信息化技术和积累的大量可再生能源数据，系统将提供防止重复计算的卓越保护和一流的可再生电力报告； ")]),_c('span',{staticClass:"article2"},[_vm._v(" ·满足可持续发展计划（包括国际的RE100、CDP、GHG Protocol等）的要求。 ")])])])
}]

export { render, staticRenderFns }