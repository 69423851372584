<template>
  <!--  <div id="container"></div>-->
  <div class='bm-view'>
    <baidu-map :center='center' :zoom='zoom' class='bm-view' @ready='handler' ak='UnifyConstant.AK'></baidu-map>
    <div style="background: rgba(255,255,255,0.5);backdrop-filter: blur(5px);width: 41.5%;height: 120px;position: absolute;top: 270px">
      <p style="position: absolute;font-size: 16px;color: #000B33;top: 18px;left: 40px">公司地址</p>
      <img src="./images/position.png" style="position: absolute;top: 60px;left: 40px">
      <p style="position: absolute;font-size: 14px;font-weight: 400;;color: #333333;top: 54px;left: 72px">
        北京市 海淀区 王庄路
      </p>
      <p style="position: absolute;font-size: 14px;font-weight: 400;color: #333333;top: 84px;left: 72px">
        清华同方科技广场D座21层
      </p>
      <p style="position: absolute;font-size: 16px;color: #000B33;top: 18px;left: 306px">联系方式</p>
      <img src="./images/infoIcon.png" style="position: absolute;top: 60px;left: 306px">
      <p style="position: absolute;font-size: 14px;font-weight: 400;color: #333333;top: 54px;left: 343px">
        greenledger@greenaccounter.cn
      </p>
    </div>
  </div>
</template>

<script>

import BaiduMap from 'vue-baidu-map/components/map/Map.vue'

export default {
  name: 'index',
  components: {
    // eslint-disable-next-line vue/no-unused-components
    BaiduMap
  },
  props: {
    // eslint-disable-next-line vue/require-prop-type-constructor
    required: true,
    passlngLatData: {
      type: Object
    }
  },
  watch: {
    passlngLatData: function (newVal, oldVal) {
      this.cData = newVal // newVal即是chartData
      // console.log(newVal, '监听数据变化')
      this.passMapData(this.passlngLatData)
    }
  },
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  mounted () {
  },
  data () {
    return {
      BMap: {},
      map: {},
      center: {
        lng: 116.345817,
        lat: 40.003944
      },
      zoom: 17
    }
  },
  methods: {
    passMapData (passlnglatData) {
      console.log(passlnglatData, 'latData')
      if (passlnglatData !== undefined && passlnglatData !== '') {
        this.center.lng = passlnglatData.lng
        this.center.lat = passlnglatData.lat
      }
    },
    handler ({
      BMap,
      map
    }) {
      this.BMap = BMap
      this.map = map
      this.center.lng = 116.345817
      this.center.lat = 40.003944
      this.zoom = 18
      // 地图定位图标
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      const myIcon = new this.BMap.Icon(require('./images/position.png'), new this.BMap.Size(30, 30), {
        anchor: new this.BMap.Size(10, 36),
        imageOffset: new this.BMap.Size(0, 0)
      })
      // 经度 纬度
      const marker = new this.BMap.Marker(new this.BMap.Point(116.345817, 40.003944), { icon: myIcon })
      // 将完成的标记加入到地图里
      this.map.addOverlay(marker)
      map.enableScrollWheelZoom(true)
    },
    passMapInfo (e) {
      this.center.lng = e.center.lng
      this.center.lat = e.center.lat
      this.zoom = e.zoom
    }
  }
}
</script>

<style scoped="scoped">
.bm-view {
  width: 100%;
  height: 100%;
}
</style>
