<template>
  <div class="mod">
    <div class="container">
      <head-nav :now-page="nowPage"></head-nav>
    </div>
    <div class="body">
      <div class="wrapper1">
        <div style="display: flex;align-items: start;flex-direction: column;margin-right: 160px;">
          <span class="title66">环境权益资产数智化解决方案提供商</span>
          <span class="meta66">您身边的“环境权益管家”</span>
        </div>
        <div>
          <img
            class="item"
            src="./images/img_2.png"
          />
        </div>
      </div>
    </div>
    <div class="container">
      <div class="box2">
        <div style="display: flex;position: relative;align-items: center;margin-top: 80px;margin-bottom: 20px">
          <div class="titleBox2">
            <img src="./images/img_3.png" class="actionBg1">
            <div class="group2">
              <span class="textTitle">碳资产</span>
              <span class="textTitle1">解决方案</span>
            </div>
            <img src="./images/img_4.png" class="buttonBg">
          </div>
        </div>
        <div class="textBox2">
          <p style="line-height: 24px">提供碳资产全流程服务，包括碳资产的开发，交易数据服务，旨在为您提供高效、专业的碳资产管理方案，与您携手为环保事业和可持续发展做出积极贡献。</p>
        </div>
      </div>
      <div class="box3">
<!--        <img src="./images/img_0.png" class="imgBox3">-->
        <div class="bodyBox3">
          <div class="textBox3Back">
            <p class="text1Box3 textBox3">项目开发</p>
            <p class="text2Box3 textBox3">碳交易</p>
            <p class="text3Box3 textBox3">数据服务</p>
          </div>
          <div class="leftDiv">
            <div class="div0 div" id="div0" v-on:click="divIn(0)">
              <p class="textaBox3 textBox3">项目开发</p>
            </div>
            <div class="div1 div" id="div1" v-on:click="divIn(1)">
              <p class="textbBox3 textBox3">碳交易</p>
            </div>
            <div class="div2 div" id="div2" v-on:click="divIn(2)">
              <p class="textcBox3 textBox3">数据服务</p>
            </div>
          </div>
          <img v-if="nowIn==0" src="./images/img_9.png" class="rightImgBox3"/>
          <img v-if="nowIn==1" src="./images/img_10.png" class="rightImgBox3"/>
          <img v-if="nowIn==2" src="./images/img_11.png" class="rightImgBox3"/>
        </div>
        <ul class="ulBox3">
          <li>
            <div class="title">
              <div class="icon1 icon"></div>
              <div class="text">项目开发</div>
            </div>
            <div class="info">
              <p class="infoText">针对减排项目，我们将提供申报文件编写、协助减排项目备案以及减排量备案等服务，助力减排资产价值实现。</p>
            </div>
          </li>
          <li>
            <div class="title">
              <div class="icon2 icon"></div>
              <div class="text">碳交易</div>
            </div>
            <div class="info">
              <p class="infoText">跟踪碳市场行情动态，基于交易价格分析制定相应的交易策略，协助企业管理碳资产交易，降低交易风险。</p>
            </div>
          </li>
          <li>
            <div class="title">
              <div class="icon3 icon"></div>
              <div class="text">数据服务</div>
            </div>
            <div class="info">
              <p class="infoText">我们将提供海量数据供您使用，包括企业数据、项目数据、资产数据等，以满足分析、查询、利用等不同功能。</p>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="box4">
      <img src="./images/img_5.png" class="imgBox4">
<!--      <img src="./images/Component 384.png" class="imgBox4">-->
<!--      <img src="./images/Group 7180.png" class="imgBox5">-->
      <div class="wordDivBox4">
        <span class="wordBox4">碳资产解决方案</span>
        <span class="labelBox4">我们提供一体化的碳资产解决方案，涵盖碳资产的全流程服务，包括碳资产开发、交易和数据服务，我们致力于为您提供高效、专业的碳资产管理方案，您将获得全面的碳资产管理支持，确保在碳减排和可持续发展目标得以实现，助力为环境保护事业做出积极贡献。我们将与您携手共同创造更加清洁、可持续的未来。 </span>
      </div>
    </div>
    <div class="container">
      <bottom-nav></bottom-nav>
    </div>
  </div>
</template>

<script>
import HeadNav from '@/components/Head/headNav' // @ is an alias to /src
import BottomNav from '@/components/Bottom/bottomNav'
export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    HeadNav,
    // eslint-disable-next-line vue/no-unused-components
    BottomNav
  },
  name: 'index',
  data () {
    return {
      nowPage: 'solution',
      nowIn: 0
    }
  },
  methods: {
    divIn (e) {
      this.divOut(this.nowIn)
      var div = document.getElementById('div' + e)
      div.className = 'FadeIn div' + e + ' div'
      this.nowIn = e
    },
    divOut (e) {
      var div = document.getElementById('div' + e)
      div.className = 'FadeOut div' + e + ' div'
      this.nowIn = e
    }
  }
}
</script>

<style scoped="scoped" src="./index.css">

</style>
