<template>
  <div class="home">
    <head-nav></head-nav>
    <div class="div1">
      <div class="div1_box">
        <div class="title_box">
          <p>碳资产数智化解决方案提供商</p>
          <p>您身边的碳管家</p>
        </div>
        <div></div>
      </div>
    </div>
    <div class="div2">
      <div class="title_box"></div>
      <div class="div2_box box"></div>
    </div>
    <div class="div3">
      <div class="title_box"></div>
      <div class="div3_box box"></div>
      <div class="btn"></div>
    </div>
    <div class="div4">
      <div class="title_box"></div>
      <div class="div4_box box"></div>
      <div class="btn"></div>
    </div>
    <div class="div5">
      <div class="title_box"></div>
      <div class="div5_box box"></div>
      <div class="btn"></div>
    </div>
<!--    <bottom-nav></bottom-nav>-->
  </div>
</template>

<script>

import headNav from '@/components/Head/headNav' // @ is an alias to /src
import bottomNav from '@/components/Bottom/bottomNav'

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    headNav,
    // eslint-disable-next-line vue/no-unused-components
    bottomNav
  }
}
</script>
