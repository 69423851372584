<template>
  <div class="mod">
    <div class="container">
      <head-nav :now-page="nowPage"></head-nav>
    </div>
    <div class="body">
      <div class="wrapper1">
        <div style="display: flex;align-items: start;flex-direction: column;margin-right: 160px;">
          <span class="title66">环境权益资产数智化解决方案提供商</span>
          <span class="meta66">您身边的“环境权益管家”</span>
        </div>
        <div>
          <img
            class="item"
            src="./images/img_2.png"
          />
        </div>
      </div>
    </div>
    <div class="container">
      <div class="submain">
        <img
          class="actionBg1"
          src="./images/img_3.png"
        />
        <div class="group2">
          <span class="title">产品</span> <span class="title1">中心</span>
        </div>
        <img
          class="buttonBg"
          src="./images/img_4.png"
        />
      </div>
      <div class="row">
        <div class="group3" v-on:click="toProduct('Two')">
          <img
            class="picture"
            src="./images/img_5.png"
          />
          <span class="word2">气候管理</span>
        </div>
        <div class="group4">
          <img
            class="image"
            src="./images/img_6.png"
          />
          <span class="label1">碳资产管理</span>
        </div>
        <div class="group5" v-on:click="toProduct('Three')">
          <img
            class="figure"
            src="./images/img_7.png"
          />
          <span class="label2">绿能跟踪</span>
        </div>
        <div class="group6" v-on:click="toProduct('Four')">
          <img
            class="bitmap"
            src="./images/img_8.png"
          />
          <span class="subtitle">碳迹源</span>
        </div>
        <!--      <span class="summary">-->
        <!--        为企业提供全面的碳资产管理和资产价值优化管理工具，识别、量化和管理碳资产，有效降低商业风险。-->
        <!--      </span>-->
    </div>
      <div class="row1">
<!--        <div class="horizontalLine" />-->
<!--        <div class="horizontalLine1" />-->
<!--        <div class="horizontalLine2" />-->
<!--        <div class="horizontalLine3" />-->
<!--        <div class="horizontalLine4" />-->
<!--        <div class="horizontalLine5" />-->
<!--        <div class="horizontalLine6" />-->
<!--        <div class="horizontalLine7" />-->
<!--        <div class="horizontalLine8" />-->
<!--        <div class="horizontalLine9" />-->
<!--        <div class="horizontalLine10" />-->
<!--        <div class="horizontalLine11" />-->
<!--        <div class="horizontalLine12" />-->
        <div class="group7">
          <span class="word3">碳资产账户</span>
          <span class="article"
          >·支持建立多种类碳资产清单以实现碳资产的集中管理；
          </span
          >
          <span class="article3">
            ·有效识别和量化所持有的碳资产类型、数量、价值，为碳资产优化配置提供准确的数据基础及分析。
          </span>
        </div>
        <img
          class="layer"
          src="./images/img_9.png"
        />
      </div>
    </div>
    <div class="row2Item0">
      <div class="row2Item0Info">
        <img
          class="cover"
          src="./images/img_10.png"
        />
        <div class="group8">
          <span class="label3">碳资产交易</span>
          <span class="article"
          >·基于碳市场行情的实时跟踪，支持碳市场交易，跟踪碳资产交易并提供完备的碳交易管理台账；
            </span
          >
          <span class="article3">
            ·利用配额试算工具和智能化交易辅助策略支撑碳资产的商业化价值，降低碳成本及风险。
          </span>
      </div>
      </div>
    </div>
    <div class="container">
      <div class="row3">
        <div class="group9">
          <span class="tag2">碳资产托管</span>
          <span class="article"
          >·适用于集中和便捷的碳资产登记和交易账户统一管理模式，助力在多种形式业务（固定收益托管、置换、质押等）下的增值服务，提升碳资产的运营优化。</span
          >
        </div>
        <img
          class="banner1"
          src="./images/img_11.png"
        />
      </div>
    </div>
    <div class="row2Item1">
      <div class="row2Item1Info">
        <img
          class="cover1"
          src="./images/img_12.png"
        />
        <div class="group10">
          <span class="tag3">碳金融评估</span>
          <span class="article"
          >·依据碳业务相关数据，评估相应的减排潜力、市场价值和未来收益等要素，结合包括市场风险、政策风险、技术风险等金融风险指标，评估碳信用等级，为参与碳金融市场提供保障。</span
          >
        </div>
      </div>
    </div>
    <div class="container">
      <bottom-nav></bottom-nav>
    </div>
  </div>
</template>
<script>
import HeadNav from '@/components/Head/headNav' // @ is an alias to /src
import BottomNav from '@/components/Bottom/bottomNav'
export default {
  components: {
    HeadNav,
    // eslint-disable-next-line vue/no-unused-components
    BottomNav
  },
  data () {
    return {
      nowPage: 'product'
    }
  },
  methods: {
    toProduct (e) {
      this.$router.push('/product' + e)
      // 将页面滚动到顶部
      window.scrollTo(0, 0)
    }
  }

}
</script>

<style scoped="scoped" src="./index.css" />
;
