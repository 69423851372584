<template>
  <div class="mod">
    <div class="container">
      <head-nav :now-page="nowPage"></head-nav>
    </div>
    <div class="body">
      <div class="wrapper1">
        <div style="display: flex;align-items: start;flex-direction: column;margin-right: 160px;">
          <span class="title66">环境权益资产数智化解决方案提供商</span>
          <span class="meta66">您身边的“环境权益管家”</span>
        </div>
        <div>
          <img
            class="item"
            src="./images/img_2.png"
          />
        </div>
      </div>
    </div>
    <div class="container">
      <div class="submain">
        <img
          class="actionBg1"
          src="./images/img_3.png"
        />
        <div class="group2">
          <span class="title">产品</span> <span class="title1">中心</span>
        </div>
        <img
          class="buttonBg"
          src="./images/img_4.png"
        />
      </div>
      <div class="row">
        <div class="group3" v-on:click="toProduct('Two')">
          <img
            class="picture"
            src="./images/img_5.png"
          />
          <span class="label2">气候管理</span>
        </div>
        <div class="group4" v-on:click="toProduct('One')">
          <img
            class="image"
            src="./images/img_6.png"
          />
          <span class="word2">碳资产管理</span>
        </div>
        <div class="group5">
          <img
            class="figure"
            src="./images/img_7.png"
          />
          <span class="label1">绿能跟踪</span>
        </div>
        <div class="group6" v-on:click="toProduct('Four')">
          <img
            class="bitmap"
            src="./images/img_8.png"
          />
          <span class="subtitle">碳迹源</span>
        </div>
      </div>
    </div>
    <div class="row2Item0">
      <img
        class="cover"
        src="./images/img_10.png"
      />
      <div class="group8">
        <span class="label3">电碳协同管理</span>
        <span class="article1"
        >·通过多账户机制设置，对可再生电力发电侧和用电测提供能量和碳协同管理；
            </span
        >
        <span class="article2">·可实现与范围2、范围3间接排放实时关联，助力制定有效的基于市场的可持续发展计划。</span>
      </div>
    </div>
    <div class="container">
      <div class="row3">
        <div class="group9">
          <span class="tag2">转移跟踪管理</span>
          <span class="article1"
          >·可灵活选择捆绑或非捆绑类型、采用实时或定期转移方式、进行强制或自愿使用行为等，实现绿能转移和使用的跟踪管理；
            </span
          >
          <span class="article2">·满足不同用户类型的绿色能源属性确权管理。</span>
        </div>
        <img
          class="banner1"
          src="./images/img_11.png"
        />
      </div>
    </div>
    <div class="row2Item1">
      <img
        class="cover1"
        src="./images/img_12.png"
      />
      <div class="group10">
        <span class="tag3">交叉验证管理</span>
        <span class="article1"
        >·基于先进信息化技术和积累的大量可再生能源数据，系统将提供防止重复计算的卓越保护和一流的可再生电力报告；
            </span
        >
        <span class="article2">
          ·满足可持续发展计划（包括国际的RE100、CDP、GHG Protocol等）的要求。
        </span>
      </div>
    </div>
    <div class="container">
      <BottomNav></BottomNav>
    </div>
  </div>
</template>
<script>
import HeadNav from '@/components/Head/headNav' // @ is an alias to /src
import BottomNav from '@/components/Bottom/bottomNav'
export default {
  components: {
    HeadNav,
    // eslint-disable-next-line vue/no-unused-components
    BottomNav
  },
  data () {
    return {
      nowPage: 'product'
    }
  },
  methods: {
    toProduct (e) {
      this.$router.push('/product' + e)
      // 将页面滚动到顶部
      window.scrollTo(0, 0)
    }
  }

}
</script>

<style scoped="scoped" src="./index.css" />
;
