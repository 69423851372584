<template>
  <div>
    <transition name="fade">
      <div v-on:touchend='end' v-on:touchstart='start' v-on:touchmove='move' class="swiper">
<!--        <div v-on:click="chooseItem(item,index)" v-for="(item, index) in imgs" :style="config5[index]" :key="item.cover">-->
<!--          <img :src="item.cover" style="width: 100%; height: 100%;">-->
<!--        </div>-->
        <div v-for="(item, index) in slotArr" :style="config5[index]" :key="index">
          <slot :name="item[(index+1)]"></slot>
        </div>
      </div>
    </transition>
    <div class="changeDiv">
      <div v-on:click="next" class="leftBtn changeBtn"></div>
      <div v-on:click="prev" class="rightBtn changeBtn"></div>
      <div class="changeLine">
        <div :class="currentIndex==1?'lineActive':'line'"></div>
        <div :class="currentIndex==0?'lineActive':'line'"></div>
        <div :class="currentIndex==2?'lineActive':'line'"></div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'rollType3',
  props: {
    slotArr: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      loading: true,
      currentIndex: 1, // 当前中间imgs数组中index
      centerInfo: '', // 当前中间信息
      startX: '',
      endX: '',
      imgs: [
        {
          id: '莱因哈特1',
          index: 0,
          cover: require('../../assets/nature-1.png')
        },
        {
          id: '安娜2',
          index: 1,
          cover: require('../../assets/nature-2.png')
        },
        {
          id: '卢西奥3',
          index: 2,
          cover: require('../../assets/nature-3.png')
        }
      ],
      previous: 0,
      config5: [
        {
          id: 'B',
          position: 'absolute',
          width: '28%',
          height: '82%',
          top: '14%',
          left: '13%',
          opacity: 1,
          zIndex: 2,
          transition: '.4s'
        },
        {
          id: 'center',
          position: 'absolute',
          width: '45%',
          height: '100%',
          top: '0px',
          left: '50%',
          marginLeft: '-22.5%',
          opacity: 1,
          zIndex: 4,
          transition: '.4s'
        },
        {
          id: 'D',
          position: 'absolute',
          width: '28%',
          height: '82%',
          top: '14%',
          left: '61.8%',
          opacity: 1,
          zIndex: 2,
          transition: '.4s'
        }
      ]
      // config5: [
      //   {
      //     id: 'B',
      //     position: 'absolute',
      //     width: '28%',
      //     height: '82%',
      //     top: '14%',
      //     left: '13%',
      //     opacity: 1,
      //     zIndex: 2,
      //     transition: '.4s'
      //   },
      //   {
      //     id: 'center',
      //     position: 'absolute',
      //     top: '0px',
      //     left: '45%',
      //     marginLeft: '-22.5%',
      //     opacity: 1,
      //     zIndex: 4,
      //     transition: '.4s'
      //   },
      //   {
      //     id: 'D',
      //     position: 'absolute',
      //     width: '28%',
      //     height: '82%',
      //     top: '14%',
      //     left: '61.8%',
      //     opacity: 1,
      //     zIndex: 2,
      //     transition: '.4s'
      //   }
      // ]
    }
  },
  methods: {
    // 获取数据
    async getData () {
      this.$nextTick(() => {
        this.loading = false
      })
    },
    // 滑动上一个
    prev (index) {
      // this.imgs.unshift(this.imgs.pop());
      this.config5.push(this.config5.shift())
      this.currentIndex = this.currentIndex - 1
      if (this.currentIndex < 0) {
        this.currentIndex = this.imgs.length - 1
      }
      this.centerCard()
      this.centerIndex('prev')
    },
    // 滑动下一个
    next () {
      // this.imgs.push(this.imgs.shift());
      this.config5.unshift(this.config5.pop())
      this.currentIndex = this.currentIndex + 1
      if (this.currentIndex > this.imgs.length - 1) {
        this.currentIndex = 0
      }
      this.centerCard()
      this.centerIndex('next')
      // console.log(this.currentIndex);
    },
    // 开始移动端滑动屏幕
    start (event) {
      this.startX = event.changedTouches[0].clientX
      this.startY = event.changedTouches[0].clientY
    },
    // 连续滑动
    move (event) {
      this.endY = event.changedTouches[0].clientY
      this.endX = event.changedTouches[0].clientX
      this.stopDefault(event)
      // 如果是滑动，注解（223行到231行）这段。如果是连续滑动，放开（223行到231行）注解
      this.interval = this.endX - this.startX
      if (this.interval > 40) {
        this.startX = this.endX
        this.prev()
      }
      if (this.interval < -40) {
        this.startX = this.endX
        this.next()
      }
    },
    // 滑动
    end (event) {
      // 如果是滑动，放开（236行到238行）的注解。如果是连续滑动，注解（236行到238行）
      // this.endY = event.changedTouches[0].clientY;
      // this.endX = event.changedTouches[0].clientX;
      // this.formatSwiper();
    },
    formatSwiper () {
      if (this.startX > this.endX) {
        console.log('左边滑动')
        if (this.startX > this.endX + 40) {
          this.next()
        }
      } else {
        console.log('右边滑动')
        if (this.endX > this.startX + 40) {
          this.prev()
        }
      }
    },
    // 阻止touchmove的横向默认事件（ios快捷操作会关闭页面）
    stopDefault (event) {
      const differenceY = this.endY - this.startY
      const differenceX = this.endX - this.startX
      if (Math.abs(differenceX) > Math.abs(differenceY)) {
        event.preventDefault()
      }
    },
    // 当前imgs在位置上的index（并非img数组的index）
    centerIndex (val) {
      if (val === 'prev') {
        for (const val of this.imgs) {
          if (val.index === this.imgs.length - 1) {
            val.index = 0
          } else {
            val.index = val.index + 1
          }
        }
      } else {
        for (const val of this.imgs) {
          if (val.index === 0) {
            val.index = this.imgs.length - 1
          } else {
            val.index = val.index - 1
          }
        }
      }
      console.log(this.currentIndex, 'index值')
    },
    // 点击
    chooseItem (index) {
      // const cycles = item.index
      // if (item.index < 3) {
      //   for (let i = 0; i < 3 - cycles; i++) {
      //     console.log(item.index)
      //     this.prev()
      //   }
      // } else if (item.index > 3) {
      //   for (let i = -1; i < item.index - 3; i++) {
      //     this.next()
      //   }
      // } else if (item.index === 3) {
      //   console.log('投票')
      // }
      const cycles = index
      if (index < 3) {
        for (let i = 0; i < 3 - cycles; i++) {
          console.log(index)
          this.prev()
        }
      } else if (index > 3) {
        for (let i = -1; i < index - 3; i++) {
          this.next()
        }
      } else if (index === 3) {
        console.log('投票')
      }
    },
    // 计算中间卡片信息
    centerCard () {
      this.centerInfo = this.imgs[this.currentIndex]
      this.$emit('centerInfo', this.centerInfo)
      // this.$emit('centerInfo', this.centerInfo);
      // console.log(this.imgs[2].id);
    },

    addCardStyle () {
      if (this.imgs.length > 7) {
        const addtime = this.imgs.length - 7
        for (let i = 0; i < addtime; i++) {
          console.log('add')
          this.config5.push({
            id: 'center',
            position: 'absolute',
            width: '45%',
            height: '100%',
            top: '0px',
            left: '50%',
            marginLeft: '-22.5%',
            opacity: 0,
            transition: '.1s'
          })
        }
      }
    }
  },
  created () {
    this.getData()
    this.centerCard() // 获取中间卡片信息
    this.addCardStyle()// 加入样式位置的index
  }
}
</script>

<style lang="less" scoped>
.changeDiv{
  display: flex;
  width: 100%;
  height: 40px;
}
.changeBtn{
  width: 7px;
  height: 13px;
  margin-right: 30px;
}
.leftBtn{
  background: url("../../assets/img1/lunboIcon.png") no-repeat;
  background-size: 100%;
}
.rightBtn{
  background: url("../../assets/img1/lunboIcon.png") no-repeat;
  transform: rotate(180deg);
  background-size: 100%;
}
.leftBtn:hover{
  background: url("../../assets/img1/lunboIconActive.png") no-repeat;
  transform: rotate(-180deg);
  background-size: 100%;
}
.rightBtn:hover{
  background: url("../../assets/img1/lunboIconActive.png") no-repeat;
  transform: rotate(0);
  background-size: 100%;
}
.changeLine{
  display: flex;
  margin-top: 5px;
}
.line,.lineActive{
  width: 190px;
  height: 2px;
  margin-left: 20px;
}
.line{
  background: #D9D9D9;
}
.lineActive{
  background: linear-gradient(89deg, #32E5FF 3%, #00C2FF 24%, #03A4FF 77%, #0085FF 100%);
}
.swiper {
  width: 100%;
  //border: 1px red solid;
  height: 400px;
  position: relative;
  overflow: hidden;
  div {
    opacity: 0;
  }
}
</style>
