<template>
  <div class="mod">
    <div class="container">
      <head-nav :now-page="nowPage"></head-nav>
    </div>
    <div class="body">
      <div class="wrapper1">
        <div style="display: flex;align-items: start;flex-direction: column;margin-right: 160px;">
          <span class="title66">环境权益资产数智化解决方案提供商</span>
          <span class="meta66">您身边的“环境权益管家”</span>
        </div>
        <div>
          <img
            class="item"
            style="width: 425px;height: 405px;"
            src="./images/img_39.png"
          />
        </div>
      </div>
    </div>
    <div class="container">
      <div style="display: flex;position: relative;align-items: center;margin-top: 80px">
        <div class="titleBox2">
          <img src="./images/img_3.png" class="actionBg1">
          <div class="group2" id="gongsi">
            <span class="textTitle">公司</span>
            <span class="textTitle1">简介</span>
          </div>
          <img src="./images/img_4.png" class="buttonBg">
        </div>
      </div>
      <div class="box2">
        <div class="bodyBox2">
          <div class="leftText">
            <div class="leftTitle">
              <span class="leftTitle1">北京</span>
              <span class="leftTitle2">壹清能环</span>
              <span class="leftTitle1">科技有限公司</span>
            </div>
            <div class="leftInfo">
              <span class="word">壹清能环致力于为</span>
              <span class="word1">政府、企业、金融机构、高校等</span>
              <span class="word">不同类型的机构提供应对气候变化的信息化产品、综合解决方案以及咨询服务。团队主要成员来自</span>
              <span class="word1">清华大学、北京大学、中科院</span>
              <span class="word">等，拥有丰富的气候变化、减排机制和数字技术领域经验。我们已为政府机关单位/中国核证自愿减排交易机构/多家500强能源企业提供了基于先进信息化技术的环境权益资产综合解决方案。</span>
<!--              <span class="word1">碳信用+区块链</span>-->
<!--              <span class="word">的综合解决方案。</span>-->
            </div>
          </div>
          <img src="./images/img_11.png" class="rightBox2">
        </div>
        <div class="bottomBox2">
          <img src="./images/img_12.png" class="bottomBox2Img">
        </div>
      </div>
    </div>
    <div class="box3">
      <div style="display: flex;position: relative;align-items: center;margin-top: 40px">
        <div class="titleBox3">
          <img src="./images/img_3.png" class="actionBg1">
          <div class="group3" id="agree">
            <span class="textTitle">企业</span>
            <span class="textTitle1">愿景</span>
          </div>
          <img src="./images/img_4.png" class="buttonBg">
        </div>
      </div>
      <div class="textBox3">
        <p>致力于环境权益资产领域的创新与发展</p>
        <p>助力推动全球绿色低碳经济可持续发展</p>
      </div>
    </div>
    <div class="container">
      <div class="box4">
        <div style="display: flex;position: relative;align-items: center;margin-top: 80px">
          <div class="titleBox3">
            <img src="./images/img_3.png" class="actionBg1">
            <div class="group3" id="rongyu">
              <span class="textTitle">荣誉</span>
              <span class="textTitle1">奖项</span>
            </div>
            <img src="./images/img_4.png" class="buttonBg">
          </div>
        </div>
<!--        <div style="margin-top: 40px">-->
<!--          <roll-type3 :slot-arr= "soltArr">-->
<!--            <div class="lunbo1" slot="lunbo1">-->
<!--              <img class="img1" src="./images/img_18.png">-->
<!--              &lt;!&ndash;              <img class="img2" src="./images/img_19.png">&ndash;&gt;-->
<!--              &lt;!&ndash;              <div textLunbo1>&ndash;&gt;-->
<!--              &lt;!&ndash;                <p>2023-05-23</p>&ndash;&gt;-->
<!--              &lt;!&ndash;                <p>发明专利</p>&ndash;&gt;-->
<!--              &lt;!&ndash;              </div>&ndash;&gt;-->
<!--              <div class="bottom66">-->
<!--                <p class="time">2023-05-23</p>-->
<!--                <p class="name">发明专利</p>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="lunbo2" slot="lunbo2">-->
<!--              <img class="img1" src="./images/img_0.png">-->
<!--              &lt;!&ndash;              <img class="img2" src="./images/img_19.png">&ndash;&gt;-->
<!--              &lt;!&ndash;              <div textLunbo2>&ndash;&gt;-->
<!--              &lt;!&ndash;                <p>2023-03-24</p>&ndash;&gt;-->
<!--              &lt;!&ndash;                <p>荣誉名称</p>&ndash;&gt;-->
<!--              &lt;!&ndash;              </div>&ndash;&gt;-->
<!--              <div class="bottom66">-->
<!--                <p class="time">2023-04-16</p>-->
<!--                <p class="name">白鹭杯全球高层次人才创新创业大赛</p>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="lunbo3" slot="lunbo3">-->
<!--              <img class="img1" src="./images/img_20.png">-->
<!--              &lt;!&ndash;              <img class="img2" src="./images/img_19.png">&ndash;&gt;-->
<!--              &lt;!&ndash;              <div textLunbo3>&ndash;&gt;-->
<!--              &lt;!&ndash;                <p>2023-03-24</p>&ndash;&gt;-->
<!--              &lt;!&ndash;                <p>中关村高新技术企业</p>&ndash;&gt;-->
<!--              &lt;!&ndash;              </div>&ndash;&gt;-->
<!--              <div class="bottom66">-->
<!--                <p class="time">2023-03-24</p>-->
<!--                <p class="name">中关村高新技术企业</p>-->
<!--              </div>-->
<!--            </div>-->
<!--          </roll-type3>-->
<!--        </div>-->
        <div style="margin-top: 40px">
          <roll-type5 :slot-arr= "soltArr">
            <div class="lunbo1 lunbo" slot="lunbo1">
              <img class="lunboimgA" src="./images/img_20.png">
              <div class="bottom66">
                <p class="time">2023-03-24</p>
                <p class="name">中关村高新技术企业</p>
              </div>
            </div>
            <div class="lunbo2 lunbo" slot="lunbo2">
              <img class="lunboimg" src="./images/img_18.png">
              <div class="bottom66">
                <p class="time">2023-05-23</p>
                <p class="name">发明专利</p>
              </div>
            </div>
            <div class="lunbo3 lunbo" slot="lunbo3">
              <img class="lunboimg" src="./images/img_0.png">
              <div class="bottom66">
                <p class="time">2023-04-16</p>
                <p class="name">白鹭杯全球高层次人才创新创业大赛</p>
              </div>
            </div>

            <div class="lunbo4 lunbo" slot="lunbo4">
              <img class="lunboimg" src="./images/lunboImg1.jpg">
              <div class="bottom66">
                <p class="time">2021-04-12</p>
                <p class="name">碳中和管理系统</p>
              </div>
            </div>
            <div class="lunbo5 lunbo" slot="lunbo5">
              <img class="lunboimg" src="./images/lunboImg2.jpg">
              <div class="bottom66">
                <p class="time">2021-01-18</p>
                <p class="name">基于区块链的能源环境资产管理系统</p>
              </div>
            </div>
            <div class="lunbo6 lunbo" slot="lunbo6">
              <img class="lunboimg" src="./images/lunboImg3.png">
              <div class="bottom66">
                <p class="time">2021-06-09</p>
                <p class="name">电力属性跟踪系统</p>
              </div>
            </div>
            <div class="lunbo7 lunbo" slot="lunbo7">
              <img class="lunboimg" src="./images/lunboImg4.png">
              <div class="bottom66">
                <p class="time">2023-03-27</p>
                <p class="name">绿证溯源验证管理系统</p>
              </div>
            </div>
            <div class="lunbo8 lunbo" slot="lunbo8">
              <img class="lunboimg" src="./images/lunboImg5.png">
              <div class="bottom66">
                <p class="time">2023-03-27</p>
                <p class="name">碳迹源小程序</p>
              </div>
            </div>
            <div class="lunbo9 lunbo" slot="lunbo9">
              <img class="lunboimg" src="./images/lunboImg6.png">
              <div class="bottom66">
                <p class="time">2023-03-27</p>
                <p class="name">碳排放管理系统</p>
              </div>
            </div>
            <div class="lunbo10 lunbo" slot="lunbo10">
              <img class="lunboimg" src="./images/lunboImg7.png">
              <div class="bottom66">
                <p class="time">2023-03-27</p>
                <p class="name">碳资产管理系统</p>
              </div>
            </div>
          </roll-type5>
        </div>
      </div>
    </div>
    <div class="box5">
      <div style="display: flex;position: relative;align-items: center;margin-top: 80px" class="titleBox5Back">
        <div class="titleBox5">
          <img src="./images/img_3.png" class="actionBg1">
          <div class="group5" id="lianxi">
            <span class="textTitle">联系</span>
            <span class="textTitle1">我们</span>
          </div>
          <img src="./images/img_4.png" class="buttonBg">
        </div>
      </div>
    </div>
    <div class="container">
      <div class="box6">
        <div class="bodyBox6">
          <div class="leftMap">
            <b-map></b-map>
          </div>
          <div class="rightInput">
            <div class="nameIn">
              <input class="inputWord" v-model="inputVal.name" placeholder="请输入您的姓名" maxlength="20" required @blur="checkSubmit">
            </div>
            <div style="position: absolute">
              <div v-if="nameError" style="color: red;float: left;position: relative;top: -22px;left: 58px">{{ nameError }}</div>
            </div>
            <div class="emailIn">
              <input class="inputWord" v-model="inputVal.email" placeholder="请输入您的邮箱" @blur="checkSubmit">
            </div>
            <div style="position: absolute">
              <div v-if="emailError" style="color: red;float: left;position: relative;top: -22px;left: 58px">{{ emailError }}</div>
            </div>
            <div class="infoIn">
              <textarea class="inputTextWord" v-model="inputVal.info" placeholder="请输入您的问题" @blur="checkSubmit"/>
            </div>
            <div style="position: absolute">
              <div v-if="infoError" style="color: red;float: left;position: relative;top: -22px;left: 58px">{{ infoError }}</div>
            </div>
            <div class="submitBtn" v-on:click="submit">
              <p class="sunmitText">提交</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="box7">
      <div style="display: flex;position: relative;align-items: center;margin-top: 20px">
        <div class="titleBox7">
          <img src="./images/img_3.png" class="actionBg1">
          <div class="group7" id="hezuo">
            <span class="textTitle">合作</span>
            <span class="textTitle1">伙伴</span>
          </div>
          <img src="./images/img_4.png" class="buttonBg">
        </div>
      </div>
      <div class="lunboBox7">
        <roll-type4 :speed="2" :direction="'right'" :slotArr="soltArr7">
          <div slot="lunbo1">
            <img src="../../assets/img1/img_29.png">
            <p class="lunboTextBox7">中国节能环保集团有限公司</p>
          </div>
          <div slot="lunbo2">
            <img src="../../assets/img1/img_30.png">
            <p class="lunboTextBox7">北京环境交易所</p>
          </div>
          <div slot="lunbo3">
            <img src="../../assets/img1/img_31.png">
            <p class="lunboTextBox7">龙净环保</p>
          </div>
          <div slot="lunbo4">
            <img src="../../assets/img1/img_32.png">
            <p class="lunboTextBox7">清华大学</p>
          </div>
          <div slot="lunbo5">
            <img src="../../assets/img1/img_33.png">
            <p class="lunboTextBox7">北大临港中心</p>
          </div>
        </roll-type4>
      </div>
    </div>
    <div class="container">
      <div style="position: relative;margin-top: -220px">
        <bottom-nav></bottom-nav>
      </div>
    </div>
  </div>
</template>
<script>
import HeadNav from '@/components/Head/headNav' // @ is an alias to /src
import BottomNav from '@/components/Bottom/bottomNav'
import rollType3 from '@/components/swipper/rollType3'
import rollType5 from '@/components/swipper/rollType5'
import rollType4 from '@/components/swipper/rollType4'
import BMap from '@/components/BaiduMap/index'
import axios from 'axios'
export default {
  components: {
    HeadNav,
    // eslint-disable-next-line vue/no-unused-components
    BottomNav,
    // eslint-disable-next-line vue/no-unused-components
    rollType3,
    rollType5,
    // eslint-disable-next-line vue/no-unused-components
    rollType4,
    // eslint-disable-next-line vue/no-unused-components
    BMap
  },
  data () {
    return {
      soltArr: [
        { 1: 'lunbo1' },
        { 2: 'lunbo2' },
        { 3: 'lunbo3' },
        { 4: 'lunbo4' },
        { 5: 'lunbo5' },
        { 6: 'lunbo6' },
        { 7: 'lunbo7' },
        { 8: 'lunbo8' },
        { 9: 'lunbo9' },
        { 10: 'lunbo10' },
      ],
      soltArr7: [
        { 1: 'lunbo1' },
        { 2: 'lunbo2' },
        { 3: 'lunbo3' },
        { 4: 'lunbo4' },
        { 5: 'lunbo5' }
      ],
      nowPage: 'aboutWe',
      inputVal: {
        name: '',
        email: '',
        info: ''
      },
      emailError: '',
      nameError: '',
      infoError: '',
      url: {
        sendInfoUrl: 'http://47.94.156.59:8081/mail/send'
      }
    }
  },
  methods: {
    checkSubmit () {
      if (this.inputVal.email === '') {
        this.emailError = '邮箱不能为空'
      } else {
        this.checkEmail()
      }
      if (this.inputVal.name === '') {
        this.nameError = '姓名不能为空'
      } else {
        this.nameError = ''
      }
      if (this.inputVal.info === '') {
        this.infoError = '你还没有写入问题'
      } else {
        this.infoError = ''
      }
      if (this.emailError && this.nameError && this.infoError) {
        return false
      } else {
        return true
      }
    },
    submit () {
      var flag = this.checkSubmit()
      if (!flag) {
        return
      }
      console.log(this.inputVal)
      axios({
        method: 'post',
        url: this.url.sendInfoUrl,
        data: this.inputVal
      }).then((res) => {
        console.log(res, '请求')
        this.$message.success('发送成功')
        this.inputVal.name = ''
        this.inputVal.email = ''
        this.inputVal.info = ''
        // this.detailqianzhunum = res.result
        // console.log(this.detailqianzhunum, 'ffffffffffffff')
      })
    },
    checkEmail () {
      const emailReg = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/
      if (!emailReg.test(this.inputVal.email)) {
        this.emailError = '请输入正确的邮箱格式'
      } else {
        this.emailError = ''
      }
    }
  }

}
</script>

<style scoped="scoped" src="./index.css" />
;
