<template>
<!--  <div class="main">-->
<!--    <img-->
<!--      class="background"-->
<!--      src="./images/img_13.png"-->
<!--    />-->
<!--    <div class="group11">-->
<!--      <img-->
<!--        class="largeIcon"-->
<!--        src="./images/img_14.png"-->
<!--      />-->
<!--      <span class="meta">碳迹源公众号</span>-->
<!--      <img-->
<!--        class="largeIcon1"-->
<!--        src="./images/img_15.png"-->
<!--      />-->
<!--      <span class="meta1">碳迹源小程序</span>-->
<!--    </div>-->
<!--    <span class="follow">关注我们</span>-->
<!--  </div>-->
  <div>
    <div class="main">
      <div class="top">
        <div class="left">
          <div class="div">
            <ul class="ulCss">
              <li>首页</li>
              <li><a href="http://greenaccounter.cn/#zonghe">综合服务</a></li>
              <li><a href="http://greenaccounter.cn/#shutan">数碳产品</a></li>
              <li><a href="http://greenaccounter.cn/#fangan">解决方案</a></li>
              <li><a href="http://greenaccounter.cn/#zixun">专业咨询</a></li>
            </ul>
          </div>
          <div class="div">
            <ul class="ulCss">
              <li>数碳产品</li>
              <li><a v-on:click="toProduct('Two')">气候管理</a></li>
              <li><a v-on:click="toProduct('One')">碳资产管理</a></li>
              <li><a v-on:click="toProduct('Three')">绿能跟踪</a></li>
              <li><a v-on:click="toProduct('Four')">碳迹源</a></li>
            </ul>
          </div>
          <div class="div">
            <ul class="ulCss">
              <li>解决方案</li>
              <li><a v-on:click="toSolution('One')">企业解决方案</a></li>
              <li><a v-on:click="toSolution('Two')">区域解决方案</a></li>
              <li><a v-on:click="toSolution('Three')">碳资产解决方案</a></li>
            </ul>
          </div>
          <div class="div">
            <ul class="ulCss">
              <li>关于我们</li>
              <li><a v-on:click="toWe('/#gongsi')">关于公司</a></li>
              <li><a v-on:click="toWe('/#agree')">企业愿景</a></li>
              <li><a v-on:click="toWe('/#rongyu')">荣誉奖项</a></li>
              <li><a v-on:click="toWe('/#hezuo')">合作伙伴</a></li>
            </ul>
          </div>
          <div>
            <ul class="ulCss">
              <li>联系我们</li>
              <li style="text-align: left;margin-left: 20px;"><a v-on:click="toWe('/#lianxi')">邮箱：greenledger@greenaccounter.cn</a></li>
            </ul>
          </div>
        </div>
        <div class="right">
          <p class="titleRight">关注我们</p>
          <div class="imgBox">
            <div class="imgDiv">
              <img src="./images/img_14.png" class="imgRight">
              <p class="textRight">碳迹源公众号</p>
            </div>
            <div class="imgDiv">
              <img src="./images/img_15.png" class="imgRight">
              <p class="textRight">碳迹源小程序</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom">
      <p class="textBottom">Copyright © 2022 北京壹清能环科技有限公司 <a href = "https://beian.miit.gov.cn" style="color: #666666">京ICP备2022004751号-1</a></p>
      <p class="textBottom">地址：北京市 海淀区 王庄路 清华同方科技广场D座21层</p>
    </div>
  </div>

</template>
<script>
export default {
  name: 'bottomNav',
  props: {
    nowPage: String
  },
  data () {
    return {
    }
  },
  methods: {
    toProduct (e) {
      this.nowPage = 'product'
      this.$router.push('/product' + e)
      // 将页面滚动到顶部
      window.scrollTo(0, 0)
      console.log('this.nowPage', this.nowPage)
    },
    toSolution (e) {
      this.nowPage = 'solution'
      this.$router.push('/solution' + e)
      // 将页面滚动到顶部
      window.scrollTo(0, 0)
      console.log('this.nowPage', this.nowPage)
    },
    toWe (e) {
      this.nowPage = 'aboutWe'
      this.$router.push('/aboutWe' + e)
      // 将页面滚动到顶部
      window.scrollTo(0, 0)
      console.log('this.nowPage', this.nowPage)
    },
  }
}
</script>

<style scoped="scoped" src="./index.css">

</style>
