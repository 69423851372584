<template>
  <div class="mod">
    <div class="container">
      <head-nav :now-page="nowPage"></head-nav>
    </div>
    <div class="body">
      <div class="wrapper1">
        <div style="display: flex;align-items: start;flex-direction: column;margin-right: 160px;">
          <span class="title66">环境权益资产数智化解决方案提供商</span>
          <span class="meta66">您身边的“环境权益管家”</span>
        </div>
        <div>
          <img
            class="item"
            src="./images/img_2.png"
          />
        </div>
      </div>
    </div>
    <div class="container">
      <div class="box2">
        <div style="display: flex;position: relative;align-items: center;margin-top: 80px;margin-bottom: 20px">
          <div class="titleBox2">
            <img src="./images/img_3.png" class="actionBg1">
            <div class="group2">
              <span class="textTitle">区域</span>
              <span class="textTitle1">解决方案</span>
            </div>
            <img src="./images/img_4.png" class="buttonBg">
          </div>
        </div>
        <div class="textBox3">
          <p style="line-height: 24px">覆盖区域内排放企业、用能企业碳资产相关数据，建立区域内碳资产管理系统，方便政府统一进行管理，让区域管理更便捷、更高效、更透明。</p>
        </div>
      </div>
      <div class="box3">
        <div class="imgIconBack">
          <img src="./images/roundIcon1.png" class="roundIcon1 roundIcon" />
          <img src="./images/roundIcon2.png" class="roundIcon2 roundIcon" />
          <img src="./images/roundIcon3.png" class="roundIcon3 roundIcon" />
          <img src="./images/roundIcon4.png" class="roundIcon4 roundIcon" />
          <img src="./images/roundIcon5.png" class="roundIcon5 roundIcon" />
        </div>
        <div class="bodyBox3">
            <div class="top div0" id="div0" v-on:click="test(0)" v-on:mousemove="mouseIn" v-on:mouseleave="mouseOut">
              <img src="./images/roundIcon1.png" class="roundIcon" />
            </div>
            <div class="oneLeft div0" id="div4" v-on:click="test(4)" v-on:mousemove="mouseIn" v-on:mouseleave="mouseOut">
              <img src="./images/roundIcon2.png" class=" roundIcon" />
            </div>
            <div class="oneRight div0" id="div1" v-on:click="test(1)" v-on:mousemove="mouseIn" v-on:mouseleave="mouseOut">
              <img src="./images/roundIcon3.png" class=" roundIcon" />
            </div>
            <div class="twoLeft div0" id="div3" v-on:click="test(3)" v-on:mousemove="mouseIn" v-on:mouseleave="mouseOut">
              <img src="./images/roundIcon4.png" class=" roundIcon" />
            </div>
            <div class="twoRight div0" id="div2" v-on:click="test(2)" v-on:mousemove="mouseIn" v-on:mouseleave="mouseOut">
              <img src="./images/roundIcon5.png" class=" roundIcon" />
            </div>
          </div>
        <div class="tishiLine">
          <div class="one lineDiv">
            <div class="topLineDiv">
              <img v-if="nowIn==0" src="./images/lineIconActive.png" class="lineIcon">
              <img v-else src="./images/lineIcon.png" class="lineIcon">
              <p class="lineText">构建一体化指标体系</p>
            </div>
            <img src="./images/lineLeft.png" class="imgLeftlineDiv">
          </div>
          <div class="two lineDiv">
            <div class="topLineDiv">
              <img v-if="nowIn==4" src="./images/lineIconActive.png" class="lineIcon">
              <img v-else src="./images/lineIcon.png" class="lineIcon">
              <p class="lineText">新基建新生数字技术</p>
            </div>
            <img src="./images/lineLeft.png" class="imgLeftlineDiv">
          </div>
          <div class="three lineDiv">
            <div class="topLineDiv" style="position: relative;top: 24px">
              <img v-if="nowIn==3" src="./images/lineIconActive.png" class="lineIcon">
              <img v-else src="./images/lineIcon.png" class="lineIcon">
              <p class="lineText">构建综合价值体系</p>
            </div>
            <img src="./images/lineLeft.png" class="imgLeftlineDiv" style="transform: rotateX(180deg)">
          </div>
          <div class="four lineDiv">
            <div class="topLineDivR" style="position: relative;top: 37px;">
              <p class="lineText">实证驱动管理优化</p>
              <img v-if="nowIn==2" src="./images/lineIconActive.png" class="lineIconR">
              <img v-else src="./images/lineIcon.png" class="lineIconR">
            </div>
            <img src="./images/lineRight.png" class="imgRightlineDiv" style="transform: rotateX(180deg)">
          </div>
          <div class="five lineDiv">
            <div class="topLineDivR">
              <p class="lineText">管理需求转化实践</p>
              <img v-if="nowIn==1" src="./images/lineIconActive.png" class="lineIconR">
              <img v-else src="./images/lineIcon.png" class="lineIconR">
            </div>
            <img src="./images/lineRight.png" class="imgRightlineDiv">
          </div>
        </div>
        <div v-if="nowIn==0" class="tagInfo0">
          <ul class="ulBox3B">
            <li>
              <span class="text">能源消耗指标</span>
            </li>
            <li>
              <span class="text">碳排放指标</span>
            </li>
            <li>
              <span class="text">碳资产指标</span>
            </li>
            <li>
              <span class="text">碳减排目标</span>
            </li>
          </ul>
          <ul class="ulBox3">
            <li>
              <span class="text">碳达峰/中和进展指标</span>
            </li>
            <li>
              <span class="text">低碳经济指标</span>
            </li>
            <li>
              <span class="text">绩效评估和比较</span>
            </li>
            <li>
              <span class="text">减排政策和措施指标</span>
            </li>
            <li>
              <span class="text">透明度和社会参与指标</span>
            </li>
          </ul>
        </div>
        <div v-if="nowIn==1" class="tagInfo0">
          <ul class="ulBox3B">
            <li>
              <span class="text">排放数据收集和监测</span>
            </li>
            <li>
              <span class="text">排放清单和报告</span>
            </li>
            <li>
              <span class="text">碳资产管理和交易</span>
            </li>
          </ul>
          <ul class="ulBox3B">
            <li>
              <span class="text">碳减排政策和措施</span>
            </li>
            <li>
              <span class="text">低碳路径创新和应用</span>
            </li>
            <li>
              <span class="text">···</span>
            </li>
          </ul>
        </div>
        <div v-if="nowIn==2" class="tagInfo0A">
          <ul  class="ulBox3">
            <li>
              <span class="text">问题研究</span>
            </li>
            <li>
              <span class="text">策略分析</span>
            </li>
            <li>
              <span class="text">政策制定</span>
            </li>
            <li>
              <span class="text">效果评估</span>
            </li>
            <li>
              <span class="text">方案改进</span>
            </li>
          </ul>
        </div>
        <div v-if="nowIn==3" class="tagInfo0A">
          <ul  class="ulBox3B">
            <li>
              <span class="text">社会价值</span>
            </li>
            <li>
              <span class="text">经济价值</span>
            </li>
            <li>
              <span class="text">生态价值</span>
            </li>
            <li>
              <span class="text">可持续发展价值</span>
            </li>
          </ul>
        </div>
        <div v-if="nowIn==4" class="tagInfo0">
          <ul class="ulBox3B">
            <li>
              <span class="text">大数据</span>
            </li>
            <li>
              <span class="text">云存储</span>
            </li>
            <li>
              <span class="text">5G</span>
            </li>
          </ul>
          <ul class="ulBox3B">
            <li>
              <span class="text">MEC</span>
            </li>
            <li>
              <span class="text">人工智能</span>
            </li>
            <li>
              <span class="text">物联网</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="box4">
      <img src="./images/img_1.png" class="imgBox4">
      <div class="wordDivBox4">
        <span class="wordBox4">区域解决方案</span>
        <span class="labelBox4">我们的区域解决方案是以国家“双碳”目标为指导，旨在协助政府建立区域全面碳管理体系，并提共科学依据和数据支持。我们致力于为区域提供便捷的工具和资源，有效监管区域内碳排放、碳交易情况，实现对行政区划内数据的精准掌握，进而提升低碳管理能力，助力区域在双碳目标下经能源、金融、科技、生活方面的系统性变革，推动区域的创新、繁荣和可持续发展。</span>
      </div>
    </div>
    <div class="container">
      <bottom-nav></bottom-nav>
    </div>
  </div>
</template>

<script>
import HeadNav from '@/components/Head/headNav' // @ is an alias to /src
import BottomNav from '@/components/Bottom/bottomNav'
export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    HeadNav,
    // eslint-disable-next-line vue/no-unused-components
    BottomNav
  },
  name: 'index',
  created () {
    this.setTimer()
  },
  data () {
    return {
      nowPage: 'solution',
      listIndex: 1,
      nowIn: 0
    }
  },
  methods: {
    setTimer () {
      this.timer = setInterval(() => {
        // eslint-disable-next-line no-undef-init
        var arr = ['top', 'oneRight', 'twoRight', 'twoLeft', 'oneLeft']
        var div0 = document.getElementById('div' + this.listIndex)
        this.nowIn = this.listIndex
        if (this.listIndex > 0) {
          var divBefore = document.getElementById('div' + (this.listIndex - 1))
          // divBefore.classList.remove('active')
          divBefore.className = 'FadeOut ' + arr[this.listIndex - 1] + ' div0'
        } else if (this.listIndex === 0) {
          var divBefore2 = document.getElementById('div4')
          // divBefore.classList.remove('active')
          divBefore2.className = 'FadeOut ' + arr[4] + ' div0'
        }
        // div0.classList.add('active')
        div0.className = 'FadeIn ' + arr[this.listIndex] + ' div0'
        console.log('FadeIn ' + arr[this.listIndex] + ' div0')
        this.listIndex++
        if (this.listIndex > 4) {
          this.listIndex = 0
        }
      }, 4000)
    },
    test (e) {
      this.divOut(this.nowIn)
      var div = document.getElementById('div' + e)
      this.nowIn = e
      switch (e) {
        case 0:
          div.className = 'FadeIn top div0'
          break
        case 1:
          div.className = 'FadeIn oneRight div0'
          break
        case 2:
          div.className = 'FadeIn twoRight div0'
          break
        case 3:
          div.className = 'FadeIn twoLeft div0'
          break
        case 4:
          div.className = 'FadeIn oneLeft div0'
          break
      }
    },
    divOut (e) {
      var div = document.getElementById('div' + e)
      this.nowIn = e
      switch (e) {
        case 0:
          div.className = 'FadeOut top div0'
          break
        case 1:
          div.className = 'FadeOut oneRight div0'
          break
        case 2:
          div.className = 'FadeOut twoRight div0'
          break
        case 3:
          div.className = 'FadeOut twoLeft div0'
          break
        case 4:
          div.className = 'FadeOut oneLeft div0'
          break
      }
    },
    mouseIn () {
      // 鼠标移入取消轮播
      clearInterval(this.timer)
      console.log('鼠标移入')
    },
    mouseOut () {
      this.listIndex = this.nowIn
      this.setTimer()
    }
  }
}
</script>

<style scoped="scoped" src="./index.css">

</style>
