<template>
  <div class="mod">
    <div class="block">
      <head-nav :now-page="nowPage"></head-nav>
    </div>
    <div class="body">
      <div class="wrapper1">
        <div style="display: flex;align-items: start;flex-direction: column;margin-right: 160px;">
          <span class="title">环境权益资产数智化解决方案提供商</span>
          <span class="meta">您身边的“环境权益管家”</span>
        </div>
        <div>
          <img
            class="item"
            src="./images/img_0.png"
          />
        </div>
      </div>
    </div>
    <div class="submain">
      <div class="wrapper3">
        <img
          class="buttonBg"
          src="./images/img_7.png"
        />
        <div class="group3" id="zonghe">
          <span class="caption">综合</span> <span class="title1">服务</span>
        </div>
        <img
          class="actionBg1"
          src="./images/img_8.png"
        />
      </div>
      <div class="wrapperText">
        <p>推动创新高效的碳减排经济发展，为实现气候行动目标创造卓越的价值。</p>
      </div>
      <div class="wrapper4">
        <div class="groupItem0">
          <img
            class="largeIcon"
            src="./images/img_9.png"
          />
          <span class="label">数碳产品 </span>
          <span class="summary"
          >以“碳”为引领，融和数智化技术强大支撑力量，为您提供高效全面的信息化产品，助力实现准确、智能、可持续的双碳管理。</span
          >
        </div>
        <div class="groupItem1">
          <img
            class="largeIcon1"
            src="./images/img_10.png"
          />
          <span class="label1">解决方案</span>
          <span class="summary1"
          >以先进技术与策略为基础，为您提供“碳”管理一体化解决方案，助力全面融入脱碳经济体系，实现经济增长与环境保护的双赢。</span
          >
        </div>
        <div class="groupItem2">
          <img
            class="largeIcon2"
            src="./images/img_11.png"
          />
          <span class="word2">专业咨询</span>
          <span class="summary2"
          >以实现双碳目标为方向，经验丰富的专业咨询团队将量身定制“能+碳+环”策略和指导，助力在气候行动中发挥领导作用。</span
          >
        </div>
      </div>
    </div>
    <div class="block">
      <div class="row">
        <div class="wrapperItem0">
          <div class="group4" id="shutan">
            <img
              class="buttonBg1"
              src="./images/img_12.png"
            />
            <span class="caption1">数碳</span>
          </div>
          <div class="group5" v-on:click="toProduct('Two')">
            <img
              class="bitmap"
              src="./images/img_13.png"
            />
            <span class="tag3">气候管理</span>
            <span class="summary3"
            >为企业提供简化碳核算和减碳规划的气候管理工具，有效应对气候变化风险并实现气候目标。</span
            >
          </div>
        </div>
        <div class="wrapperItem1">
          <div class="group6">
            <span class="title2">产品</span>
            <img
              class="actionBg2"
              src="./images/img_14.png"
            />
          </div>
          <div class="group7" v-on:click="toProduct('One')">
            <img
              class="picture"
              src="./images/img_15.png"
            />
            <span class="word3">碳资产</span>
            <span class="summary4"
            >为企业提供全面的碳资产管理和资产价值优化管理工具，识别、量化和管理碳资产，有效降低商业风险。</span
            >
          </div>
        </div>
      </div>
      <div class="row1">
        <div class="colItem0" v-on:click="toProduct('Three')">
          <img
            class="picture1"
            src="./images/img_16.png"
          />
          <span class="label2">绿能跟踪</span>
          <span class="summary5"
          >绿能跟踪系统为寻求制定包括基于市场在内的可持续发展计划的用户提供安全、可靠、灵活的管理基础设施。</span
          >
        </div>
        <div class="colItem1" v-on:click="toProduct('Four')">
          <img
            class="image"
            src="./images/img_17.png"
          />
          <span class="tag4">碳迹源</span>
          <span class="summary6"
          >“碳迹源”小程序提供多元化的碳资产溯源工具，满足用户随时随地在移动端查看环境权益相关数据的需求。</span
          >
        </div>
      </div>
    </div>
    <div class="row2">
      <div class="wrapper6">
        <img
          class="buttonBg2"
          src="./images/img_20.png"
        />
        <div class="group8" id="fangan">
          <span class="caption2">解决</span>
          <span class="caption3">方案</span>
        </div>
        <img
          class="actionBg3"
          src="./images/img_21.png"
        />
      </div>
      <!--      企业解决方案-->
      <div class="wrapper7" v-show="flag1" v-on:click="toSolution('One')">
        <img
          class="logo"
          src="./images/img_22.png"
        />
        <div class="group9">
          <img
            class="thumbnail"
            src="./images/img_41.png"
          />
          <div class="view">
            <span class="tag5">企业解决方案</span>
            <span class="dataService">Enterprise Solutions</span>
          </div>
        </div>
        <span class="article"
        >我们致力于为企业搭建双碳管理体系，提供碳核算、碳交易、脱碳规划的全方位支持，助力提高企业的整体碳管理能力，为企业打造绿色形象，实现碳中和，并为在低碳经济时代保持领先地位提供有力保障。通过我们的综合性解决方案，您将获得准确的碳数据分析和战略指导，助力实现气候目标并创造可持续的商业成果。让我们一起迈向低碳未来，共同打造一个可持续发展的企业。</span
        >
      </div>
      <!--      区域解决方案-->
      <div class="wrapper7" v-show="flag2" v-on:click="toSolution('Two')">
        <img
          class="logo"
          src="./images/img_22.png"
        />
        <div class="group9">
          <img
            class="thumbnail"
            src="./images/img_40.png"
          />
          <div class="view">
            <span class="tag5">区域解决方案</span>
            <span class="dataService">Regional Solutions</span>
          </div>
        </div>
        <span class="article"
        >我们的区域解决方案是以国家“双碳”目标为指导，旨在协助政府建立区域全面碳管理体系，并提共科学依据和数据支持。我们致力于为区域提供便捷的工具和资源，有效监管区域内碳排放、碳交易情况，实现对行政区划内数据的精准掌握，进而提升低碳管理能力，助力区域在双碳目标下经济、能源、金融、科技、生活方面的系统性变革，推动区域的创新、繁荣和可持续发展。</span
        >
      </div>
      <!--      碳资产解决方案-->
      <div class="wrapper7" v-show="flag3" v-on:click="toSolution('Three')">
          <img
            class="logo"
            src="./images/img_22.png"
          />
          <div class="group9">
            <img
              class="thumbnail"
              src="./images/img_23.png"
            />
            <div class="view">
              <span class="tag5">碳资产解决方案</span>
              <span class="dataService">Integrated Carbon Asset Solution</span>
            </div>
          </div>
          <span class="article"
          >我们提供一体化的碳资产解决方案，涵盖碳资产的全流程服务，包括碳资产开发、交易和数据服务，我们致力于为您提供高效、专业的碳资产管理方案，您将获得全面的碳资产管理支持，确保在碳减排和可持续发展目标得以实现，助力为环境保护事业做出积极贡献。我们将与您携手共同创造更加清洁、可持续的未来。</span
          >
      </div>
      <div class="line1" :class="active==1 ? 'line1' : 'line1-1'" @click="changePage(1)"></div>
      <div class="line2" :class="active==2 ? 'line2-2' : 'line2'" @click="changePage(2)"></div>
      <div class="line3" :class="active==3 ? 'line3-3' : 'line3'" @click="changePage(3)"></div>
      <img src="./images/mouse.png" class="mouse">
    </div>
    <div class="block">
      <div class="main">
        <img
          class="actionBg4"
          src="./images/img_24.png"
        />
        <div class="wrapper8" id="zixun">
          <span class="title3">专业</span> <span class="caption4">咨询</span>
        </div>
        <img
          class="buttonBg3"
          src="./images/img_25.png"
        />
      </div>
      <div class="row3">
        <span class="meta1">能源发展方案</span>
        <span class="label3">碳达峰方案</span>
        <span class="word4">碳盘查</span>
        <span class="subtitle">碳中和路径设计</span>
      </div>
      <img
        class="floorBg"
        src="./images/img_26.png"
      />
      <div class="row4">
        <span class="info1">碳资产开发</span>
        <span class="subtitle1">节能减排规划</span>
        <span class="label4">ESG报告</span>
      </div>
      <div class="row5">
        <a href="/aboutWe/#lianxi">
          <div class="moreWrapper"><span class="more">More</span></div>
          <div class="">
            <img
              class="horizontalLine"
              src="./images/img_27.png"
            />
          </div>
        </a>
      </div>
    </div>
    <div class="box7">
      <div style="display: flex;position: relative;align-items: center;margin-top: 20px">
        <div class="titleBox7">
          <img src="./images/img_24.png" class="actionBg1Box7">
          <div class="groupBox7" id="hezuo">
            <span class="textTitle">合作</span>
            <span class="textTitle1">伙伴</span>
          </div>
          <img src="./images/img_25.png" class="buttonBgBox7">
        </div>
      </div>
      <div class="lunboBox7">
        <roll-type4 :speed="2" :direction="'right'" :slotArr="soltArr7">
          <div slot="lunbo1">
            <img src="../../assets/img1/img_29.png">
            <p class="lunboTextBox7">中国节能环保集团有限公司</p>
          </div>
          <div slot="lunbo2">
            <img src="../../assets/img1/img_30.png">
            <p class="lunboTextBox7">北京环境交易所</p>
          </div>
          <div slot="lunbo3">
            <img src="../../assets/img1/img_31.png">
            <p class="lunboTextBox7">龙净环保</p>
          </div>
          <div slot="lunbo4">
            <img src="../../assets/img1/img_32.png">
            <p class="lunboTextBox7">清华大学</p>
          </div>
          <div slot="lunbo5">
            <img src="../../assets/img1/img_33.png">
            <p class="lunboTextBox7">北大临港中心</p>
          </div>
        </roll-type4>
      </div>
    </div>
    <div class="block">
      <bottom-nav></bottom-nav>
    </div>
  </div>
</template>
<script>

import HeadNav from '@/components/Head/headNav' // @ is an alias to /src
import BottomNav from '@/components/Bottom/bottomNav'
import rollType4 from '@/components/swipper/rollType4'
export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    HeadNav,
    // eslint-disable-next-line vue/no-unused-components
    BottomNav,
    // eslint-disable-next-line vue/no-unused-components
    rollType4
  },
  data () {
    return {
      nowPage: 'home',
      constants: {},
      soltArr7: [
        { 1: 'lunbo1' },
        { 2: 'lunbo2' },
        { 3: 'lunbo3' },
        { 4: 'lunbo4' },
        { 5: 'lunbo5' }
      ],
      flag1: true,
      flag2: false,
      flag3: false,
      active: 1,
      timer: '',
      pageIndex : 0
    }
  },
  mounted () {
    this.timer = setInterval(() => {		// 定时器一般写mounted() 生命周期内
      if (this.pageIndex > 4) {
        this.pageIndex = 0
      }
      this.changePage(this.pageIndex += 1)
    }, 5000)
  },
  methods: {
    toProduct (e) {
      this.$router.push('/product' + e)
      // 将页面滚动到顶部
      window.scrollTo(0, 0)
    },
    toSolution (e) {
      this.$router.push('/solution' + e)
      // 将页面滚动到顶部
      window.scrollTo(0, 0)
    },
    changePage(pageIndex) {
      if (pageIndex === 1) {
        this.flag1 = true
        this.flag2 = false
        this.flag3 = false
        this.active = pageIndex
      } else if (pageIndex === 2) {
        this.flag1 = false
        this.flag2 = true
        this.flag3 = false
        this.active = pageIndex
      } else if (pageIndex === 3) {
        this.flag1 = false
        this.flag2 = false
        this.flag3 = true
        this.active = pageIndex
      }
    }
  }
}
</script>

<style scoped="scoped" src="./index.css" />
;
