var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mod"},[_c('div',{staticClass:"container"},[_c('head-nav',{attrs:{"now-page":_vm.nowPage}})],1),_vm._m(0),_c('div',{staticClass:"container"},[_vm._m(1),_c('div',{staticClass:"row"},[_vm._m(2),_c('div',{staticClass:"group4",on:{"click":function($event){return _vm.toProduct('One')}}},[_c('img',{staticClass:"image",attrs:{"src":require("./images/img_6.png")}}),_c('span',{staticClass:"word2"},[_vm._v("碳资产管理")])]),_c('div',{staticClass:"group5",on:{"click":function($event){return _vm.toProduct('Three')}}},[_c('img',{staticClass:"figure",attrs:{"src":require("./images/img_7.png")}}),_c('span',{staticClass:"label2"},[_vm._v("绿能跟踪")])]),_c('div',{staticClass:"group6",on:{"click":function($event){return _vm.toProduct('Four')}}},[_c('img',{staticClass:"bitmap",attrs:{"src":require("./images/img_8.png")}}),_c('span',{staticClass:"subtitle"},[_vm._v("碳迹源")])])]),_vm._m(3)]),_vm._m(4),_vm._m(5),_vm._m(6),_c('div',{staticClass:"container"},[_c('BottomNav')],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"body"},[_c('div',{staticClass:"wrapper1"},[_c('div',{staticStyle:{"display":"flex","align-items":"start","flex-direction":"column","margin-right":"160px"}},[_c('span',{staticClass:"title66"},[_vm._v("环境权益资产数智化解决方案提供商")]),_c('span',{staticClass:"meta66"},[_vm._v("您身边的“环境权益管家”")])]),_c('div',[_c('img',{staticClass:"item",attrs:{"src":require("./images/img_2.png")}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"submain"},[_c('img',{staticClass:"actionBg1",attrs:{"src":require("./images/img_3.png")}}),_c('div',{staticClass:"group2"},[_c('span',{staticClass:"title"},[_vm._v("产品")]),_vm._v(" "),_c('span',{staticClass:"title1"},[_vm._v("中心")])]),_c('img',{staticClass:"buttonBg",attrs:{"src":require("./images/img_4.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"group3"},[_c('img',{staticClass:"picture",attrs:{"src":require("./images/img_5.png")}}),_c('span',{staticClass:"label1"},[_vm._v("气候管理")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row1"},[_c('div',{staticClass:"group7"},[_c('span',{staticClass:"word3"},[_vm._v("数据采集监测")]),_c('span',{staticClass:"article"},[_vm._v("·支持从不同的数据源自动化获取数据，实现简化收集、整理、监测温室气体排放数据、能源消耗数据等； ")]),_c('span',{staticClass:"article2"},[_vm._v("·支持人工参与数据填报并进行交叉验证，提高数据质量系数。 ")])]),_c('img',{staticClass:"layer",attrs:{"src":require("./images/img_9.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row2Item0"},[_c('img',{staticClass:"cover",attrs:{"src":require("./images/img_10.png")}}),_c('div',{staticClass:"group8"},[_c('span',{staticClass:"label3"},[_vm._v("碳排放核算")]),_c('span',{staticClass:"article1"},[_vm._v("·碳数据引擎识别不同排放源（能源消耗、工业生产、运输活动、运营活动等）； ")]),_c('span',{staticClass:"article2"},[_vm._v("·依据国内国际认可的行业标准，依托整合的大量数据，快速计算业务中的每吨碳排放。 ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"row3"},[_c('div',{staticClass:"group9"},[_c('span',{staticClass:"tag2"},[_vm._v("双碳目标跟踪")]),_c('span',{staticClass:"article1"},[_vm._v("·基于多维度的数据分析，并结合现有和未来的气候项目，以行业、排放数据、目标等为起点，根据需求定制脱碳战略，配置行动和实施时间表，跟踪并立即获得减排行动的影响和反馈。")])]),_c('img',{staticClass:"banner1",attrs:{"src":require("./images/img_11.png")}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row2Item1"},[_c('img',{staticClass:"cover1",attrs:{"src":require("./images/img_12.png")}}),_c('div',{staticClass:"group10"},[_c('span',{staticClass:"tag3"},[_vm._v("报告及合规管理")]),_c('span',{staticClass:"article1"},[_vm._v("·将数据管理、统计分析和信息披露相结合，生成符合标准的、特定格式化的气候管理相关报告，满足不同管理机构、利益相关方等外部报告和合规要求。")])])])
}]

export { render, staticRenderFns }