<template>
  <div class="mod">
    <div class="container">
      <head-nav :now-page="nowPage"></head-nav>
    </div>
    <div class="body">
      <div class="wrapper1">
        <div style="display: flex;align-items: start;flex-direction: column;margin-right: 160px;">
          <span class="title66">环境权益资产数智化解决方案提供商</span>
          <span class="meta66">您身边的“环境权益管家”</span>
        </div>
        <div>
          <img
            class="item"
            src="./images/img_2.png"
          />
        </div>
      </div>
    </div>
    <div class="container">
      <div class="submain">
        <img
          class="actionBg1"
          src="./images/img_3.png"
        />
        <div class="group2">
          <span class="title">产品</span> <span class="title1">中心</span>
        </div>
        <img
          class="buttonBg"
          src="./images/img_4.png"
        />
      </div>
      <div class="row">
        <div class="group3" v-on:click="toProduct('Two')">
          <img
            class="picture"
            src="./images/img_5.png"
          />
          <span class="label2">气候管理</span>
        </div>
        <div class="group4" v-on:click="toProduct('One')">
          <img
            class="image"
            src="./images/img_6.png"
          />
          <span class="word2">碳资产管理</span>
        </div>
        <div class="group5" v-on:click="toProduct('Three')">
          <img
            class="figure"
            src="./images/img_7.png"
          />
          <span class="subtitle">绿能跟踪</span>
        </div>
        <div class="group6">
          <img
            class="bitmap"
            src="./images/img_8.png"
          />
          <span class="label1">碳迹源</span>
        </div>
      </div>
    </div>
    <div class="row2Item0">
      <img
        class="cover"
        src="./images/img_9.png"
      />
      <div class="group8">
        <span class="label3">碳信用项目</span>
        <span class="article1"
        >·涵盖了CCER、CDM、GEC、IREC多机制减排项目，数据积累量200000+。</span
        >
      </div>
    </div>
    <div class="container">
      <div class="row3">
        <div class="group9">
          <span class="tag2">方法学</span>
          <span class="article2"
          >·针对不同减排技术在研究边界内所核算出相对于二氧化减排量的方法。</span
          >
        </div>
        <img
          class="banner1"
          src="./images/img_10.png"
        />
      </div>
    </div>
    <div class="row2Item1">
      <img
        class="cover1"
        src="./images/img_11.png"
      />
      <div class="group10">
        <span class="tag3">信用签注</span>
        <span class="article3"
        >·基于碳信用项目，对这些项目的签发和注销数据进行详细展示。</span
        >
      </div>
    </div>
    <div class="container">
      <div class="row1">
        <!--        <div class="horizontalLine" />-->
        <!--        <div class="horizontalLine1" />-->
        <!--        <div class="horizontalLine2" />-->
        <!--        <div class="horizontalLine3" />-->
        <!--        <div class="horizontalLine4" />-->
        <!--        <div class="horizontalLine5" />-->
        <!--        <div class="horizontalLine6" />-->
        <!--        <div class="horizontalLine7" />-->
        <!--        <div class="horizontalLine8" />-->
        <!--        <div class="horizontalLine9" />-->
        <!--        <div class="horizontalLine10" />-->
        <!--        <div class="horizontalLine11" />-->
        <!--        <div class="horizontalLine12" />-->
        <div class="group7">
          <span class="word3">碳市场</span>
          <span class="article1"
          >·依托全国碳市场与试点碳市场，展示不同碳市场行情和碳市场主体的详细信息。
          </span
          >
        </div>
        <img
          class="layer"
          src="./images/img_12.png"
        />
      </div>
      <BottomNav></BottomNav>
    </div>
  </div>
</template>
<script>
import HeadNav from '@/components/Head/headNav' // @ is an alias to /src
import BottomNav from '@/components/Bottom/bottomNav'
export default {
  components: {
    HeadNav,
    // eslint-disable-next-line vue/no-unused-components
    BottomNav
  },
  data () {
    return {
      nowPage: 'product'
    }
  },
  methods: {
    toProduct (e) {
      this.$router.push('/product' + e)
      // 将页面滚动到顶部
      window.scrollTo(0, 0)
    }
  }

}
</script>

<style scoped="scoped" src="./index.css" />
;
