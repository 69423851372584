var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mod"},[_c('div',{staticClass:"container"},[_c('head-nav',{attrs:{"now-page":_vm.nowPage}})],1),_vm._m(0),_c('div',{staticClass:"container"},[_vm._m(1),_c('div',{staticClass:"row"},[_c('div',{staticClass:"group3",on:{"click":function($event){return _vm.toProduct('Two')}}},[_c('img',{staticClass:"picture",attrs:{"src":require("./images/img_5.png")}}),_c('span',{staticClass:"word2"},[_vm._v("气候管理")])]),_vm._m(2),_c('div',{staticClass:"group5",on:{"click":function($event){return _vm.toProduct('Three')}}},[_c('img',{staticClass:"figure",attrs:{"src":require("./images/img_7.png")}}),_c('span',{staticClass:"label2"},[_vm._v("绿能跟踪")])]),_c('div',{staticClass:"group6",on:{"click":function($event){return _vm.toProduct('Four')}}},[_c('img',{staticClass:"bitmap",attrs:{"src":require("./images/img_8.png")}}),_c('span',{staticClass:"subtitle"},[_vm._v("碳迹源")])])]),_vm._m(3)]),_vm._m(4),_vm._m(5),_vm._m(6),_c('div',{staticClass:"container"},[_c('bottom-nav')],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"body"},[_c('div',{staticClass:"wrapper1"},[_c('div',{staticStyle:{"display":"flex","align-items":"start","flex-direction":"column","margin-right":"160px"}},[_c('span',{staticClass:"title66"},[_vm._v("环境权益资产数智化解决方案提供商")]),_c('span',{staticClass:"meta66"},[_vm._v("您身边的“环境权益管家”")])]),_c('div',[_c('img',{staticClass:"item",attrs:{"src":require("./images/img_2.png")}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"submain"},[_c('img',{staticClass:"actionBg1",attrs:{"src":require("./images/img_3.png")}}),_c('div',{staticClass:"group2"},[_c('span',{staticClass:"title"},[_vm._v("产品")]),_vm._v(" "),_c('span',{staticClass:"title1"},[_vm._v("中心")])]),_c('img',{staticClass:"buttonBg",attrs:{"src":require("./images/img_4.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"group4"},[_c('img',{staticClass:"image",attrs:{"src":require("./images/img_6.png")}}),_c('span',{staticClass:"label1"},[_vm._v("碳资产管理")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row1"},[_c('div',{staticClass:"group7"},[_c('span',{staticClass:"word3"},[_vm._v("碳资产账户")]),_c('span',{staticClass:"article"},[_vm._v("·支持建立多种类碳资产清单以实现碳资产的集中管理； ")]),_c('span',{staticClass:"article3"},[_vm._v(" ·有效识别和量化所持有的碳资产类型、数量、价值，为碳资产优化配置提供准确的数据基础及分析。 ")])]),_c('img',{staticClass:"layer",attrs:{"src":require("./images/img_9.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row2Item0"},[_c('div',{staticClass:"row2Item0Info"},[_c('img',{staticClass:"cover",attrs:{"src":require("./images/img_10.png")}}),_c('div',{staticClass:"group8"},[_c('span',{staticClass:"label3"},[_vm._v("碳资产交易")]),_c('span',{staticClass:"article"},[_vm._v("·基于碳市场行情的实时跟踪，支持碳市场交易，跟踪碳资产交易并提供完备的碳交易管理台账； ")]),_c('span',{staticClass:"article3"},[_vm._v(" ·利用配额试算工具和智能化交易辅助策略支撑碳资产的商业化价值，降低碳成本及风险。 ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"row3"},[_c('div',{staticClass:"group9"},[_c('span',{staticClass:"tag2"},[_vm._v("碳资产托管")]),_c('span',{staticClass:"article"},[_vm._v("·适用于集中和便捷的碳资产登记和交易账户统一管理模式，助力在多种形式业务（固定收益托管、置换、质押等）下的增值服务，提升碳资产的运营优化。")])]),_c('img',{staticClass:"banner1",attrs:{"src":require("./images/img_11.png")}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row2Item1"},[_c('div',{staticClass:"row2Item1Info"},[_c('img',{staticClass:"cover1",attrs:{"src":require("./images/img_12.png")}}),_c('div',{staticClass:"group10"},[_c('span',{staticClass:"tag3"},[_vm._v("碳金融评估")]),_c('span',{staticClass:"article"},[_vm._v("·依据碳业务相关数据，评估相应的减排潜力、市场价值和未来收益等要素，结合包括市场风险、政策风险、技术风险等金融风险指标，评估碳信用等级，为参与碳金融市场提供保障。")])])])])
}]

export { render, staticRenderFns }