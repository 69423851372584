var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mod"},[_c('div',{staticClass:"container"},[_c('head-nav',{attrs:{"now-page":_vm.nowPage}})],1),_vm._m(0),_c('div',{staticClass:"container"},[_vm._m(1),_c('div',{staticClass:"row"},[_c('div',{staticClass:"group3",on:{"click":function($event){return _vm.toProduct('Two')}}},[_c('img',{staticClass:"picture",attrs:{"src":require("./images/img_5.png")}}),_c('span',{staticClass:"label2"},[_vm._v("气候管理")])]),_c('div',{staticClass:"group4",on:{"click":function($event){return _vm.toProduct('One')}}},[_c('img',{staticClass:"image",attrs:{"src":require("./images/img_6.png")}}),_c('span',{staticClass:"word2"},[_vm._v("碳资产管理")])]),_c('div',{staticClass:"group5",on:{"click":function($event){return _vm.toProduct('Three')}}},[_c('img',{staticClass:"figure",attrs:{"src":require("./images/img_7.png")}}),_c('span',{staticClass:"subtitle"},[_vm._v("绿能跟踪")])]),_vm._m(2)])]),_vm._m(3),_vm._m(4),_vm._m(5),_c('div',{staticClass:"container"},[_vm._m(6),_c('BottomNav')],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"body"},[_c('div',{staticClass:"wrapper1"},[_c('div',{staticStyle:{"display":"flex","align-items":"start","flex-direction":"column","margin-right":"160px"}},[_c('span',{staticClass:"title66"},[_vm._v("环境权益资产数智化解决方案提供商")]),_c('span',{staticClass:"meta66"},[_vm._v("您身边的“环境权益管家”")])]),_c('div',[_c('img',{staticClass:"item",attrs:{"src":require("./images/img_2.png")}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"submain"},[_c('img',{staticClass:"actionBg1",attrs:{"src":require("./images/img_3.png")}}),_c('div',{staticClass:"group2"},[_c('span',{staticClass:"title"},[_vm._v("产品")]),_vm._v(" "),_c('span',{staticClass:"title1"},[_vm._v("中心")])]),_c('img',{staticClass:"buttonBg",attrs:{"src":require("./images/img_4.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"group6"},[_c('img',{staticClass:"bitmap",attrs:{"src":require("./images/img_8.png")}}),_c('span',{staticClass:"label1"},[_vm._v("碳迹源")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row2Item0"},[_c('img',{staticClass:"cover",attrs:{"src":require("./images/img_9.png")}}),_c('div',{staticClass:"group8"},[_c('span',{staticClass:"label3"},[_vm._v("碳信用项目")]),_c('span',{staticClass:"article1"},[_vm._v("·涵盖了CCER、CDM、GEC、IREC多机制减排项目，数据积累量200000+。")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"row3"},[_c('div',{staticClass:"group9"},[_c('span',{staticClass:"tag2"},[_vm._v("方法学")]),_c('span',{staticClass:"article2"},[_vm._v("·针对不同减排技术在研究边界内所核算出相对于二氧化减排量的方法。")])]),_c('img',{staticClass:"banner1",attrs:{"src":require("./images/img_10.png")}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row2Item1"},[_c('img',{staticClass:"cover1",attrs:{"src":require("./images/img_11.png")}}),_c('div',{staticClass:"group10"},[_c('span',{staticClass:"tag3"},[_vm._v("信用签注")]),_c('span',{staticClass:"article3"},[_vm._v("·基于碳信用项目，对这些项目的签发和注销数据进行详细展示。")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row1"},[_c('div',{staticClass:"group7"},[_c('span',{staticClass:"word3"},[_vm._v("碳市场")]),_c('span',{staticClass:"article1"},[_vm._v("·依托全国碳市场与试点碳市场，展示不同碳市场行情和碳市场主体的详细信息。 ")])]),_c('img',{staticClass:"layer",attrs:{"src":require("./images/img_12.png")}})])
}]

export { render, staticRenderFns }