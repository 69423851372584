<template>
  <div>
    <a-carousel autoplay ref="img">
      <div><h3>1</h3></div>
      <div><h3>2</h3></div>
      <div><h3>3</h3></div>
      <div><h3>4</h3></div>
    </a-carousel>
    <div v-on:click="handlePrev">left</div>
    <div v-on:click="handleNext">right</div>
  </div>
</template>

<script>
export default {
  name: 'test',
  methods: {
    handlePrev() {
      // 通过上边指定的ref 来操作
      this.$refs.img.prev();
    },
    handleNext() {
      this.$refs.img.next();
    }
  }
}
</script>

<style scoped>
/* For demo */
.ant-carousel >>> .slick-slide {
  text-align: center;
  height: 160px;
  line-height: 160px;
  background: #364d79;
  overflow: hidden;
}

.ant-carousel >>> .slick-slide h3 {
  color: #fff;
}
</style>
