<template>
  <div class="mod">
    <div class="container">
      <head-nav :now-page="nowPage"></head-nav>
    </div>
    <div class="body">
      <div class="wrapper1">
        <div style="display: flex;align-items: start;flex-direction: column;margin-right: 160px;">
          <span class="title66">环境权益资产数智化解决方案提供商</span>
          <span class="meta66">您身边的“环境权益管家”</span>
        </div>
        <div>
          <img
            class="item"
            src="./images/img_2.png"
          />
        </div>
      </div>
    </div>
    <div class="container">
      <div class="submain">
        <img
          class="actionBg1"
          src="./images/img_3.png"
        />
        <div class="group2">
          <span class="title">产品</span> <span class="title1">中心</span>
        </div>
        <img
          class="buttonBg"
          src="./images/img_4.png"
        />
      </div>
      <div class="row">
        <div class="group3">
          <img
            class="picture"
            src="./images/img_5.png"
          />
          <span class="label1">气候管理</span>
        </div>
        <div class="group4" v-on:click="toProduct('One')">
          <img
            class="image"
            src="./images/img_6.png"
          />
          <span class="word2">碳资产管理</span>
        </div>
        <div class="group5" v-on:click="toProduct('Three')">
          <img
            class="figure"
            src="./images/img_7.png"
          />
          <span class="label2">绿能跟踪</span>
        </div>
        <div class="group6" v-on:click="toProduct('Four')">
          <img
            class="bitmap"
            src="./images/img_8.png"
          />
          <span class="subtitle">碳迹源</span>
        </div>
    </div>
<!--      <span class="summary"-->
<!--        >为企业提供全面的碳资产管理和资产价值优化管理工具，识别、量化和管理碳资产，有效降低商业风险。</span-->
<!--      >-->
      <div class="row1">
<!--        <div class="horizontalLine" />-->
<!--        <div class="horizontalLine1" />-->
<!--        <div class="horizontalLine2" />-->
<!--        <div class="horizontalLine3" />-->
<!--        <div class="horizontalLine4" />-->
<!--        <div class="horizontalLine5" />-->
<!--        <div class="horizontalLine6" />-->
<!--        <div class="horizontalLine7" />-->
<!--        <div class="horizontalLine8" />-->
<!--        <div class="horizontalLine9" />-->
<!--        <div class="horizontalLine10" />-->
<!--        <div class="horizontalLine11" />-->
<!--        <div class="horizontalLine12" />-->
        <div class="group7">
          <span class="word3">数据采集监测</span>
          <span class="article"
            >·支持从不同的数据源自动化获取数据，实现简化收集、整理、监测温室气体排放数据、能源消耗数据等；
          </span
          >
          <span class="article2"
            >·支持人工参与数据填报并进行交叉验证，提高数据质量系数。
          </span
          >
        </div>
        <img
          class="layer"
          src="./images/img_9.png"
        />
      </div>
    </div>
    <div class="row2Item0">
      <img
        class="cover"
        src="./images/img_10.png"
      />
      <div class="group8">
        <span class="label3">碳排放核算</span>
        <span class="article1"
        >·碳数据引擎识别不同排放源（能源消耗、工业生产、运输活动、运营活动等）；
            </span
        >
        <span class="article2"
        >·依据国内国际认可的行业标准，依托整合的大量数据，快速计算业务中的每吨碳排放。
            </span
            >
      </div>
    </div>
    <div class="container">
      <div class="row3">
        <div class="group9">
          <span class="tag2">双碳目标跟踪</span>
          <span class="article1"
          >·基于多维度的数据分析，并结合现有和未来的气候项目，以行业、排放数据、目标等为起点，根据需求定制脱碳战略，配置行动和实施时间表，跟踪并立即获得减排行动的影响和反馈。</span
          >
        </div>
        <img
          class="banner1"
          src="./images/img_11.png"
        />
      </div>
    </div>
    <div class="row2Item1">
      <img
        class="cover1"
        src="./images/img_12.png"
      />
      <div class="group10">
        <span class="tag3">报告及合规管理</span>
        <span class="article1"
        >·将数据管理、统计分析和信息披露相结合，生成符合标准的、特定格式化的气候管理相关报告，满足不同管理机构、利益相关方等外部报告和合规要求。</span
        >
      </div>
    </div>
    <div class="container">
      <BottomNav></BottomNav>
    </div>
  </div>
</template>
<script>
import HeadNav from '@/components/Head/headNav' // @ is an alias to /src
import BottomNav from '@/components/Bottom/bottomNav'
export default {
  components: {
    HeadNav,
    // eslint-disable-next-line vue/no-unused-components
    BottomNav
  },
  data () {
    return {
      nowPage: 'product'
    }
  },
  methods: {
    toProduct (e) {
      this.$router.push('/product' + e)
      // 将页面滚动到顶部
      window.scrollTo(0, 0)
    }
  }

}
</script>

<style scoped="scoped" src="./index.css" />
;
